import React, { useEffect, useState } from 'react';
import Logo from '../images/logo.png';
import SideNav from '../components/SideNav';
import Footer from '../components/Footer';
import ToggleNav from '../components/ToggleNav';
import ImgProfile from '../images/img-profile.svg';
import fullscreeen from '../images/fullscreen.png'
import zoomin from '../images/zoomin.png'
import zoomout from '../images/zoomout.png'
import iconCalendar from '../images/iconCalendar.svg';
import IconExcel from '../images/IconExcel.svg';
import IconPPT from '../images/IconPPT.svg';
import IconAI from '../images/IconAI.svg';
import IconFigma from '../images/IconFigma.svg';
import IconFolder from '../images/folder.svg';
import ReactPlayer from 'react-player';
import Avatar1 from '../images/Avatar1.svg';
import Avatar2 from '../images/Avatar2.svg';
import Avatar3 from '../images/Avatar3.svg';
import Avatar4 from '../images/Avatar4.svg';

import ImgStolityApp from '../images/img-stolity-app.png';
import { ReactComponent as PasswordShow } from "../images/icon-eye.svg";
import { ReactComponent as PasswordHide } from "../images/icon-eye-hide.svg";
import axios from "axios"

import { Progress, Modal } from 'antd';

import { ChakraProvider, Stack, useToast } from '@chakra-ui/react';

import { Tooltip, Whisper, SelectPicker, Dropdown, Popover, Placeholder, Button } from 'rsuite';
import { Modal as Bigmodal } from 'rsuite';


const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const months = [
  { name: '1 Month', code: '01' },
  { name: '2 Month', code: '02' },
  { name: '3 Month', code: '03' },
  { name: '4 Month', code: '04' },
  { name: '5 Month', code: '05' },
  { name: '6 Month', code: '06' },
  { name: '7 Month', code: '07' },
  { name: '8 Month', code: '08' },
  { name: '9 Month', code: '09' },
  { name: '10 Month', code: '10' },
  { name: '11 Month', code: '11' },
  { name: '12 Month', code: '12' },
];

const UserProfile = () => {
  const [sizeFormat,setSizeFormat] = useState(0)
  const apiUrl = process.env.REACT_APP_API_ENDPOINT;
  const email = sessionStorage.getItem("email");
  const token = sessionStorage.getItem("number");
  const namee = sessionStorage.getItem("name")
  const numnum = sessionStorage.getItem("num")
  const avatar = sessionStorage.getItem("avatar")
 
  const authType = sessionStorage.getItem("authType")

  const [selectedAvatar, setSelectedAvatar] = useState("")
  const [oldPass, setOldPass] = useState("")
  const [newPass, setNewPass] = useState("")
  const [confirmPass, setConfirmPass] = useState("")
  const [name, setName] = useState("")
  const [eemail, setEemail] = useState("")
  const [number, setNumber] = useState("")
  const [errors, setErrors] = React.useState({ name: '', number: '', email: '' });

  useEffect(() => {
    setEemail(email)
    setName(namee)
    setNumber(numnum)
  }, [])

  const handleConfirmPassChange = (e) => {
    setConfirmPass(e.target.value)
  }

  const handleNewPassChange = (e) => {
    setNewPass(e.target.value)
  }

  const handleOldPassChange = (e) => {
    setOldPass(e.target.value)
  }

  const handleChangeName = (e) => {
    const newValue = e.target.value;
    setName(newValue);
    setErrors((prev) => ({ ...prev, name: validateName(newValue) }));
  };
  
  const handleChangeNumber = (e) => {
    const newValue = e.target.value;
    // Only allow numeric input
    const numericValue = newValue.replace(/\D/g, '');
    
    // Limit to 10 digits
    const limitedValue = numericValue.slice(0, 10);
    
    setNumber(limitedValue);
    
    // Validate only when the input reaches 10 digits
    const numberError = validateNumber(limitedValue);
    setErrors((prev) => ({ ...prev, number: numberError }));
  };
  
  const handleChangeEmail = (e) => {
    const newValue = e.target.value;
    setEemail(newValue);
    setErrors((prev) => ({ ...prev, email: validateEmail(newValue) }));
  };

  const validateName = (name) => {
    if (!name) return 'Name is required.';
    if (name.length < 3) return 'Name should be at least 3 characters long.';
    if (!/^[a-zA-Z ]+$/.test(name)) return 'Name should contain only letters.';
    return '';
  };
  
  
const validateNumber = (number) => {
  if (!number) return 'Mobile number is required.';
  if (number.length < 10) return 'Mobile number must be 10 digits.';
  return '';
};
  
  const validateEmail = (email) => {
    if (!email) return 'Email is required.';
    if (!/\S+@\S+\.\S+/.test(email)) return 'Please enter a valid email address.';
    return '';
  };

  const handleChangeInfo = async (e) => {
    e.preventDefault();
  
    const nameError = validateName(name);
    const numberError = validateNumber(number);
    const emailError = validateEmail(eemail);
  
    setErrors({ name: nameError, number: numberError, email: emailError });
  
    if (!nameError && !numberError && !emailError) {
      try {
        console.log(eemail, name, number);
        const avatarToPass = selectedAvatar || avatar;
  
        const res = await axios.post(
          `${apiUrl}edit-user`,
          {
            email: eemail,
            name: name,
            contact: number,
            userAvatar: avatarToPass || "https://nodeserver-filestorage.s3.ap-south-1.amazonaws.com/Avatars/man_1.svg",
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            }
          }
        );
  
        console.log(res.data);
  
        showToast('success', "Data is updated!");
        handleCancel();
  
        sessionStorage.setItem("email", eemail);
        sessionStorage.setItem("name", name);
        sessionStorage.setItem("num", number);
        sessionStorage.setItem("avatar", avatarToPass);
      } catch (error) {
        console.error("There's error at", error);
      } finally {
        setEemail(sessionStorage.getItem("email"));
        setName(sessionStorage.getItem("name"));
        setNumber(sessionStorage.getItem("num"));
      }
    }
  };



  const handlePassWordChange = async () => {
    const res = await axios.post(
      `${apiUrl}change-password`,
      {
        email: email,
        oldPassword: oldPass,
        newPassword: newPass,
        confirmNewPassword: confirmPass
      },
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }
    );
    showToast('success', "Password is changed!")
    console.log("res", res)
    handleCancel()
  }

  const handleReset = () => {
    setConfirmPass("")
    setNewPass("");
    setOldPass("");
  }

  const toast = useToast();


  const showToast = (status, message) => {
    toast({
      title: `${status.charAt(0).toUpperCase() + status.slice(1)}`,
      description: message,
      status: status, // Set this to 'error' for a red-colored pop-up
      duration: 3000,
      isClosable: true,
    });
  };

  const [rootsize, setRootSize] = useState('')


  const [selectedMonth, setSelectedMonth] = useState('06');

  // MODAL
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false); // This will close the modal
  };
  // END MODAL


  // PASSWORD SHOW HIDE

  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showPassword3, setShowPassword3] = useState(false);

  const togglePasswordVisibility1 = () => setShowPassword1(!showPassword1);
  const togglePasswordVisibility2 = () => setShowPassword2(!showPassword2);
  const togglePasswordVisibility3 = () => setShowPassword3(!showPassword3);


  // AVATAR ACTIVE
  const [activeIndex, setActiveIndex] = useState(null);

  const handleButtonClick = (index) => {
    setActiveIndex(index); // Click par active button ka index update hota hai
  };
  const [avatarBox, setavatarBox] = useState({})
  const getAllAvatars = async () => {
    try {
      const response = await axios.get(`${apiUrl}get-avatar-list`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
      console.log(response.data.avatarList)
      setavatarBox(response.data.avatarList)

    }
    catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    console.log("avatar box is", avatarBox);
  }, [avatarBox]); // This effect runs whenever avatarBox changes.

  const [remainingStorage, setRemainingStorage] = useState("")
  const getRootFolderSize = async () => {
    try {
      const res = await axios.get(`${apiUrl}get-folder-size`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      })
      setRootSize(res.data.totalSize)

      setRemainingStorage(res.data.totalSize.substring(0, res.data.totalSize.indexOf(' ')))
      setSizeFormat(res.data.sizeInBytes)
      console.log("Size in bytes",res.data.sizeInBytes)
      console.log("remaining a", res.data.totalSize.substring(0, res.data.totalSize.indexOf(' ')))
      
    }
    catch (error) {
      console.error(`There's error at ${error}`)
    }

  }
  useEffect(() => {
    getRootFolderSize()
    getAllAvatars()
  },
    [])
  const [files, setFiles] = useState([])
  const getRecentFiles = async () => {
    try {
      const res = await axios.get(`${apiUrl}get-recent-files`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      })
      setFiles(res.data)
      console.log("Recent files are", res.data)

    }
    catch (error) {
      console.error(`There's error at ${error}`)
    }

  }
  useEffect(() => {
    getRecentFiles()
  },
    [])

  const [errorMessage2, setErrorMessage2] = useState("");
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showImage, setShowImage] = useState(false)
  const [videoSrc, setVideoSrc] = useState('');
  const [imageSrc, setImageSrc] = useState('');
  const [pdfSrc, setPdfSrc] = useState('')
  const [audioSrc, setAudioSrc] = useState('');
  const [isProgressVisible, setIsProgressVisible] = useState(false);
  const [modalFile, setModalFile] = useState("")



  //Image getting function
  const getImageInfo = async (filename) => {
    try {
      const res = await axios.get(`${apiUrl}getFile`, {
        params: {
          filePath: filename,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'arraybuffer',
      });
      setIsProgressVisible(false)
      console.log("Image is clicked")
      const base64Image = arrayBufferToBase64(res.data);
      const fileType = res.headers['content-type'];
      setImageSrc(`data:${fileType};base64,${base64Image}`)
    }
    catch (error) {
      console.error(error);
    }
  }
  //Audio getting function
  const getAudioInfo = async (filename) => {
    try {
      const res = await axios.get(`${apiUrl}getFile`, {
        params: {
          filePath: filename,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'arraybuffer',
      });
      setIsProgressVisible(false)
      const fileType = res.headers['content-type'];
      const blob = new Blob([res.data], { type: fileType });
      const url = window.URL.createObjectURL(blob);
      setAudioSrc(url);

    }
    catch (error) {
      console.error(error);
    }
  }

  //Pdf getting function
  const getPdfInfo = async (filename) => {
    try {
      const res = await axios.get(`${apiUrl}getFile`, {
        params: {
          filePath: filename,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'arraybuffer',
      });
      setIsProgressVisible(false)
      const fileType = res.headers['content-type'];
      const blob = new Blob([res.data], { type: fileType });
      const url = window.URL.createObjectURL(blob);
      setPdfSrc(url);

    }
    catch (error) {
      console.error(error);
    }
  }
  function arrayBufferToBase64(buffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }
  const fileTypeExtractor = (file) => {
    const partBeforeSlash = file.split('/')[0];
    return partBeforeSlash
  }

  useEffect(() => {
    return () => {
      if (audioSrc) {
        URL.revokeObjectURL(audioSrc);
      }
    };
  }, [audioSrc]);


  const zoomIn = () => setZoomLevel((prevZoom) => Math.min(prevZoom + 0.2, 3));
  const zoomOut = () => setZoomLevel((prevZoom) => Math.max(prevZoom - 0.2, 1));
  const toggleFullscreen = () => setIsFullscreen((prev) => !prev);

  const handleImageShow = () => setShowImage(true)
  const handleImageClose = () => {
    console.log("close button clicked!")
    setShowImage(false)
    setCurrentImageIndex(0)
    setImageSrc("")
    setVideoSrc("")
    setAudioSrc("")
    setPdfSrc("")

  }

  const handleNext = () => {
    setErrorMessage2("")
    setIsProgressVisible(true)
    setCurrentImageIndex((prevIndex) => {
      let newIndex = (prevIndex + 1) % files.length;
      let fileType = files[newIndex].fileType;

      // Loop to find the next item that is a file (has a fileType)
      while (!fileType && files.length > 0) {
        newIndex = (newIndex + 1) % files.length;
        fileType = files[newIndex].fileType;
      }

      if (fileType === 'mp3' || fileType === 'MP3' || fileType === 'wav' || fileType === 'WAV' || fileType === 'ogg' || fileType === 'OGG' || fileType === 'aac' || fileType === 'AAC') {
        setImageSrc("")
        setVideoSrc("")
        setPdfSrc("")
        getAudioInfo(files[newIndex].fileName);
        setModalFile(files[newIndex].fileName)
      }


      else if (fileType === 'jpeg' || fileType === 'jpg' || fileType === 'png' || fileType === 'gif' || fileType === 'hevc' || fileType === 'heif' || fileType === 'JPEG' || fileType === 'JPG' || fileType === 'PNG' || fileType === 'GIF' || fileType === 'HEVC' || fileType === 'HEIF' || fileType === 'svg' || fileType === 'SVG' || fileType === 'webp' || fileType === 'WEBP') {
        setVideoSrc("")
        setAudioSrc("")
        setPdfSrc("")
        getImageInfo(files[newIndex].fileName);
        console.log("mantra", files[newIndex].fileName)
        setModalFile(files[newIndex].fileName)
      }

      else if (fileType === 'pdf' || fileType === 'PDF' || fileType === 'txt' || fileType === 'TXT') {
        setImageSrc("")
        setVideoSrc("")
        setAudioSrc("")
        getPdfInfo(files[newIndex].fileName);
        setModalFile(files[newIndex].fileName)
      }

      else if (fileType === 'mkv' || fileType === 'mp4' || fileType === 'mov' || fileType === 'mpeg' || fileType === 'webm' || fileType === 'MOV') {
        setImageSrc("")
        setAudioSrc("")
        setPdfSrc("")
        setIsProgressVisible(false)
        setVideoSrc(files[newIndex].fileName);
        setModalFile(files[newIndex].fileName)
      }

      else {
        setImageSrc("")
        setAudioSrc("")
        setPdfSrc("")
        setVideoSrc("")
        setIsProgressVisible(false)
        setErrorMessage2("Unsupported file format");
        setModalFile(files[newIndex].fileName)
      }


      setZoomLevel(1);
      return newIndex;

    });
  };

  const handlePrev = () => {
    setErrorMessage2("")
    setIsProgressVisible(true)
    setCurrentImageIndex((prevIndex) => {
      let newIndex = (prevIndex - 1 + files.length) % files.length;
      let fileType = files[newIndex].fileType;

      // Loop to find the next item that is a file (has a fileType)
      while (!fileType && files.length > 0) {
        newIndex = (newIndex - 1 + files.length) % files.length;
        fileType = files[newIndex].fileType;
      }


      if (fileType === 'mp3' || fileType === 'MP3' || fileType === 'wav' || fileType === 'WAV' || fileType === 'ogg' || fileType === 'OGG' || fileType === 'aac' || fileType === 'AAC') {
        setImageSrc("")
        setVideoSrc("")
        setPdfSrc("")
        getAudioInfo(files[newIndex].fileName);
        setModalFile(files[newIndex].fileName)
      }


      else if (fileType === 'jpeg' || fileType === 'jpg' || fileType === 'png' || fileType === 'gif' || fileType === 'hevc' || fileType === 'heif' || fileType === 'JPEG' || fileType === 'JPG' || fileType === 'PNG' || fileType === 'GIF' || fileType === 'HEVC' || fileType === 'HEIF' || fileType === 'svg' || fileType === 'SVG' || fileType === 'webp' || fileType === 'WEBP') {
        setVideoSrc("")
        setAudioSrc("")
        setPdfSrc("")
        getImageInfo(files[newIndex].fileName);
        setModalFile(files[newIndex].fileName)
      }

      else if (fileType === 'pdf' || fileType === 'PDF' || fileType === 'txt' || fileType === 'TXT') {
        setImageSrc("")
        setVideoSrc("")
        setAudioSrc("")
        getPdfInfo(files[newIndex].fileName);
        setModalFile(files[newIndex].fileName)
      }

      else if (fileType === 'mkv' || fileType === 'mp4' || fileType === 'mov' || fileType === 'mpeg' || fileType === 'webm' || fileType === 'MOV') {
        setImageSrc("")
        setAudioSrc("")
        setPdfSrc("")
        setIsProgressVisible(false)
        setVideoSrc(files[newIndex].fileName);
        setModalFile(files[newIndex].fileName)
      }

      else {
        setImageSrc("")
        setAudioSrc("")
        setPdfSrc("")
        setVideoSrc("")
        setIsProgressVisible(false)
        setErrorMessage2("Unsupported file format");
        setModalFile(files[newIndex].fileName)
      }
      setZoomLevel(1);
      return newIndex;

    });
  };



  //Handle prev by arrow
  useEffect(() => {
    // Function to handle keydown events
    const handleKeyDown = (event) => {
      if (showImage && event.key === "ArrowLeft") {
        handlePrev(); // Trigger the function when the left arrow key is pressed and the modal is open
      }
    };

    // Add the event listener when the component mounts
    window.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [showImage]);


  useEffect(() => {
    // Function to handle keydown events
    const handleKeyDown = (event) => {
      if (showImage) {
        if (event.key === "ArrowLeft") {
          handlePrev(); // Trigger handlePrev when the left arrow key is pressed
        } else if (event.key === "ArrowRight") {
          handleNext(); // Trigger handleNext when the right arrow key is pressed
        }
      }
    };

    // Add the event listener when the component mounts
    window.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [showImage]);

  function getTextAfterLastSlash(text) {
    if (text.includes("/")) {
      return text.substring(text.lastIndexOf('/') + 1);
    }
    else {
      return text;
    }


  }




  return (
    <>
      <SideNav />
      <div className="container-fluid page-body-wrapper">
        <nav className="navbar p-0 fixed-top d-flex flex-row">
          <div className="navbar-brand-wrapper d-flex d-lg-none align-items-center justify-content-center">
            <a className="navbar-brand brand-logo-mini" href="#">
              <img src={Logo} alt="logo" />
            </a>
          </div>
          <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
            <ToggleNav />
            <div className="navbar-nav page_title">
              <h1>User Profile</h1>
            </div>
          </div>
        </nav>

        {/* Main Content */}
        <div className="main-panel">
          <div className="content-wrapper">
            <div className='row'>
              <div className='col-lg-6 mt-2'>
                <div className='profile_box'>
                  <div className='profile_row'>
                    <div className='img_profile'>
                      <img width="170" src={avatar?avatar:Avatar2} />
                    </div>

                    <div className='profile_text'>
                      <h5> {name}</h5>
                      <h6>User Permission: <span>View Only</span></h6>
                      <p><span>Email:</span> {eemail}</p>
                      <p><span>Mobile:</span> {number}</p>
                      <button className='btn__edit__profile' onClick={showModal}>Edit Profile</button>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-lg-6 mt-2'>
                <div className='profile_box'>
                  <div className='profile_row'>
                    <div className='CircularProgress_bar' style={{ textAlign: 'center', paddingLeft: 30 }}>
                      <Progress
                        type="circle"
                        percent={Math.round((sizeFormat / 21474836480) * 100)}
                        strokeWidth={25} // Adjust thickness
                        strokeColor="#F68D2B" // Color of progress
                        trailColor="#FFE7C6" // Background color of the circle
                        strokeLinecap="butt" // Shape of the stroke ends
                        format={(percent) => (
                          <div>
                            <div className='percent_text'>{Math.round((sizeFormat / 21474836480) * 100)}%</div> {/* Show the percentage */}
                            <div style={{ fontSize: '13px', color: '#BDBDBD', fontWeight: 600 }}>Used</div> {/* Text below percentage */}
                          </div>
                        )}
                        size={180} // Circle size
                      />
                    </div>

                    <div className='storage_text'>
                      <div className='storage_used'>
                        <h5>Storage Used</h5>
                        <h4>{rootsize}</h4>
                      </div>

                      <div className='storage_remaining'>
                        <h5>Storage Remaining</h5>
                        <h4>{Math.round((21474836480 - sizeFormat)/ (1024 ** 3)*100)/100} GB</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='profile_row'>
              <div className='profile_table_box'>
                <div className='profile_table_title_row'>
                  <h5>Recent Uploads</h5>

                </div>

                <div className='recent_table_box'>
                  <div className='table-responsive'>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>File Name</th>
                          <th className='text-center'>Date Uploaded</th>
                          <th className='text_right'>File Size</th>
                          <th className='text-center'>Type</th>
                        </tr>
                      </thead>
                      {files.map((file, map) => {
                        return (


                          <tbody>

                            <tr>
                              <td>
                                <div className='filename_row'>
                                  <span><img src={file.icon} height={32} /></span>
                                  <span
                                    onClick={

                                      () => {
                                        setErrorMessage2("")

                                        if (file.fileType === 'mkv' || file.fileType === 'mp4' || file.fileType === 'mov' || file.fileType === 'mpeg' || file.fileType === 'webm' || file.fileType === 'MOV') {

                                          setModalFile(file.fileName)
                                          handleImageShow()
                                          const fname = file.fileName


                                          const index = files.findIndex(file => file.fileName === fname);
                                          if (index !== -1) {
                                            setCurrentImageIndex(index);
                                            console.log("index is ", index)
                                          } else {
                                            console.warn(`File with name "${fname}" not found in imageArray`);
                                          }
                                          setVideoSrc(file.fileName)
                                          setModalFile(file.fileName)

                                        }
                                        else if (file.fileType === 'jpeg' || file.fileType === 'jpg' || file.fileType === 'png' || file.fileType === 'gif' || file.fileType === 'hevc' || file.fileType === 'heif' || file.fileType === 'JPEG' || file.fileType === 'JPG' || file.fileType === 'PNG' || file.fileType === 'GIF' || file.fileType === 'HEVC' || file.fileType === 'HEIF' || file.fileType === 'svg' || file.fileType === 'SVG' || file.fileType === 'webp' || file.fileType === 'WEBP') {

                                          setModalFile(file.fileName)
                                          handleImageShow()
                                          const fname = file.fileName
                                          console.log("Starting function call with fname:", fname);

                                          const index = files.findIndex(file => file.fileName === fname);
                                          if (index !== -1) {
                                            setCurrentImageIndex(index);
                                            console.log("index is ", index)
                                          } else {
                                            console.warn(`File with name "${fname}" not found in imageArray`);
                                          }


                                          getImageInfo(file.fileName)


                                        }
                                        else if (file.fileType === 'mp3' || file.fileType === 'MP3' || file.fileType === 'wav' || file.fileType === 'WAV' || file.fileType === 'ogg' || file.fileType === 'OGG' || file.fileType === 'aac' || file.fileType === 'AAC') {
                                          setModalFile(file.fileName)
                                          handleImageShow()
                                          const fname = file.fileName
                                          console.log("Starting function call with fname:", fname);

                                          const index = files.findIndex(file => file.fileName === fname);
                                          if (index !== -1) {
                                            setCurrentImageIndex(index);
                                            console.log("index is ", index)
                                          } else {
                                            console.warn(`File with name "${fname}" not found in imageArray`);
                                          }
                                          getAudioInfo(file.fileName)
                                        }


                                        else if (file.fileType === 'pdf' || file.fileType === 'PDF' || file.fileType === 'txt' || file.fileType === 'TXT') {

                                          setModalFile(file.fileName)
                                          handleImageShow()
                                          const fname = file.fileName
                                          console.log("Starting function call with fname:", fname);

                                          const index = files.findIndex(file => file.fileName === fname);
                                          if (index !== -1) {
                                            setCurrentImageIndex(index);
                                            console.log("index is ", index)
                                          } else {
                                            console.warn(`File with name "${fname}" not found in imageArray`);
                                          }
                                          getPdfInfo(file.fileName)
                                        }


                                        else {
                                          if (file.isFolder === true) {
                                            console.log("Its a folder.")

                                          }
                                          else {
                                            handleImageShow()
                                            setErrorMessage2("File format not supported!")

                                            const fname = file.fileName
                                            console.log("Starting function call with fname:", fname);

                                            const index = files.findIndex(file => file.fileName === fname);
                                            if (index !== -1) {
                                              setCurrentImageIndex(index);
                                              console.log("index is ", index)
                                            } else {
                                              console.warn(`File with name "${fname}" not found in imageArray`);
                                            }
                                          }
                                        }




                                      }
                                    }

                                    className='filename_link'>{
                                      getTextAfterLastSlash(file.fileName)}</span>
                                </div>
                              </td>
                              <td className='text-center'>{file.uploadDateTime}</td>
                              <td className='text_right'>{file.fileSize}</td>
                              <td className='text-center'>
                                <div className='type_pp'>{file.ACL}</div>
                              </td>
                            </tr>


                          </tbody>
                        )
                      })}
                    </table>
                  </div>
                </div>

              </div>

              <div className='profile_stolity_app'>
                <h5>Get the Stolity <br />App today!</h5>
                <br />
                Available on both the App <br />Store & Play Store
                <div className='profile_stolity_app_bottom'>
                  <a href='https://play.google.com/store/apps/details?id=com.stolity&pcampaignid=web_share' className='btn_app_download'>Download</a>
                  <img src={ImgStolityApp} className='img_responsive' />
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
        {/* main-panel ends */}
      </div>

      <Modal title="" open={isModalOpen} footer={null} onCancel={handleCancel} className="profileModal_Wrap">
        <div className='edit_profile_modal'>
          <h4 className='text-center'>Edit Profile</h4>
          <div className='avatar_profile'>
            <h6 className='text-center'>AVATAR SELECTION</h6>
            <div className='avatar_profile_list'>
              {[Avatar2, Avatar4, Avatar3, Avatar1].map((avatar, index) => (
                <button
                  key={index}
                  className={`btn_avatar ${activeIndex === index ? 'active' : ''}`}
                  onClick={() => {
                    setSelectedAvatar(avatarBox[index + 1].url); // Update the selected avatar
                    setActiveIndex(index); // Update the active index
                  }}
                >
                  <img src={avatar} alt={`Avatar ${index + 1}`} />
                  
                </button>

              ))}
            </div>
          </div>

          <div className='personal_detail_profile'>
            <h5>PERSONAL INFORMATION</h5>
            <form onSubmit={handleChangeInfo}>
            <div className='row' style={{ alignItems: 'flex-end' }}>
              <div className='col-sm-4 mt-4 '>
                <label className='form_label'>Name</label>
                <input
                  value={name}
                  onChange={handleChangeName}
                  type='text' className='form-control' placeholder='Enter Name' />
                  {errors.name && <span style={{ color: 'red' }}>{errors.name}</span>}
              </div>


              <div className='col-sm-4 mt-4'>
                <label className='form_label'>Mobile</label>
                <input
                  value={number}
                  onChange={handleChangeNumber}
                  type='text' className='form-control' placeholder='Enter Mobile No.' />
                  {errors.number && <span style={{ color: 'red' }}>{errors.number}</span>}
              </div>

              <div className='col-sm-4 mt-4 '>
                <label className='form_label'>Email</label>
                <input
                  value={eemail}
                  onChange={handleChangeEmail}
                  type='email' className='form-control' placeholder='Enter Email ID' />
                  {errors.email && <span style={{ color: 'red' }}>{errors.email}</span>}
              </div>

              
            </div>
            <div className='col-sm-6 mt-4 ' style={{"marginLeft":"220px"}}>
                <button type='submit' className='btn__save_changes' >Save Changes</button>
              </div>
            </form>
          </div>

          <div className='personal_detail_profile'>
            <h5>Password</h5>
            <div className='row'>
              <div className='col-sm-6 col-md-4 mt-4'>
                <label className='form_label'>Current Password</label>
                <div className='text_field'>
                  <input type={showPassword1 ? 'text' : 'password'}
                    value={oldPass} disabled={authType === "google"} onChange={handleOldPassChange}
                    className="form-control" placeholder='Current Password' />
                  <div className='icon_field' onClick={togglePasswordVisibility1}>
                    {showPassword1 ? <PasswordShow /> : <PasswordHide />}
                  </div>
                </div>
              </div>

              <div className='col-sm-6 col-md-4 mt-4'>
                <label className='form_label'>New Password</label>
                <div className='text_field'>
                  <input disabled={authType === "google"} type={showPassword2 ? 'text' : 'password'}
                    value={newPass} onChange={handleNewPassChange}
                    className="form-control" placeholder='New Password' />
                  <div className='icon_field' onClick={togglePasswordVisibility2}>
                    {showPassword2 ? <PasswordShow /> : <PasswordHide />}
                  </div>
                </div>
              </div>

              <div className='col-sm-6 col-md-4 mt-4'>
                <label className='form_label'>Confirm Password</label>
                <div className='text_field'>
                  <input disabled={authType === "google"} type={showPassword3 ? 'text' : 'password'}
                    value={confirmPass} onChange={handleConfirmPassChange}
                    className="form-control" placeholder='Confirm Password' />
                  <div className='icon_field' onClick={togglePasswordVisibility3}>
                    {showPassword3 ? <PasswordShow /> : <PasswordHide />}
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div className='btn__groups mt-4'>
            <button disabled={authType === "google"} className='btn__cancel' onClick={handleReset}>Reset</button>
            <button disabled={authType === "google"} className='btn__save_changes' onClick={handlePassWordChange}>Save Changes</button>
          </div>
        </div>
      </Modal>

      {/*All files Shower */}
      <Bigmodal
        open={showImage}
        onClose={handleImageClose}
        className={`pdf_modal_style file_upload_modal_style ${isFullscreen ? 'fullscreen-modal' : ''}`}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: isFullscreen ? 'rgba(0, 0, 0, 0.8)' : 'rgba(0, 0, 0, 0.5)',
          padding: isFullscreen ? '0' : '20px',
        }}
      >
        <Button
          onClick={handleImageClose}
          variant="secondary"
          className="file-popup-close"
          id="closePopup"
          style={{ position: 'absolute', top: '10px', right: '10px', fontSize: '24px', color: 'white', width: '50px', background: 'grey', zIndex: '999', opacity: '100' }}
        >
          &times; {/* Close Icon */}
        </Button>

        <Bigmodal.Body style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
          {isProgressVisible && (
            <span className="loader" ></span> // Added loader at the top
          )}
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
            <Button onClick={handlePrev} style={{ marginRight: '10px' }}>❮</Button>

            <div
              style={{
                width: isFullscreen ? '100vw' : '80%',
                height: isFullscreen ? '100vh' : 'auto',
                maxWidth: '90%',
                maxHeight: isFullscreen ? '100vh' : '80vh',
                overflow: 'hidden',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                transform: `scale(${zoomLevel})`,
                transition: 'transform 0.3s ease',
              }}
            >
              {videoSrc ? (
                <ReactPlayer
                  url={`${apiUrl}getFileDefault?token=${token}&filePath=${videoSrc}`}
                  controls
                  playing={true}
                  width="100%"
                  height="100%"
                />
              ) : pdfSrc ? (
                <iframe src={pdfSrc} border="0" width="100%" style={{ height: '100vh', border: 0 }} />
              ) : imageSrc ? (
                <img src={imageSrc} alt="Image format not supported" style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
              ) : audioSrc ?
                (
                  <div className='audio_player_modal'>
                    <audio controls style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                      position: 'relative',
                    }}>
                      <source src={audioSrc} type="audio/ogg" />
                      <source src={audioSrc} type="audio/mpeg" />
                      <source src={audioSrc} type="audio/wav" />
                      <source src={audioSrc} type="audio/mp3" />
                      Your browser does not support the audio element.
                    </audio>


                  </div>
                )
                :
                (
                  errorMessage2 ? <p>{errorMessage2}</p> :
                    <p></p>

                )
              }
            </div>


            <Button onClick={handleNext} style={{ marginLeft: '10px' }}>❯</Button>
          </div>



          <div style={{ marginTop: '15px', display: 'flex', gap: '10px' }}>
            <Button onClick={zoomIn}>
              <img src={zoomin} alt="Zoom In" style={{ width: '24px', height: '24px' }} />
            </Button>
            <Button onClick={zoomOut}>
              <img src={zoomout} alt="Zoom Out" style={{ width: '24px', height: '24px' }} />
            </Button>
            <Button onClick={toggleFullscreen}>
              <img src={fullscreeen} alt="Zoom Out" style={{ width: '24px', height: '24px' }} />
            </Button>

          </div>
        </Bigmodal.Body>
      </Bigmodal>



    </>
  );
}

export default UserProfile;
