import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Logo from '../images/logo.png';
import ToggleNav from '../components/ToggleNav'
// import Logo from '../images/logo.png';
// import LogoMini from '../images/logo-mini.png';

import LogoStolity from '../images/LogoStolity.png';
import LogoStolityMini from '../images/LogoStolityMini.png';
import { ChakraProvider,  Stack, useToast } from '@chakra-ui/react';

import axios from 'axios'
import { useSelector } from 'react-redux'

const SideNav = () => {
    const location = useLocation();
    const [openMenus, setOpenMenus] = useState({});
    const [activeMenu, setActiveMenu] = useState(null);
    const [sidebarOpen, setSidebarOpen] = useState(true); // State to manage sidebar visibility
    // Anurag's declaration
    const navigate = useNavigate()
    const token = sessionStorage.getItem("number");
    const count = useSelector((state) => state.getdata.counter)
    const apiUrl = process.env.REACT_APP_API_ENDPOINT;
    // Function to handle main menu click
    const handleMenuClick = (menu) => {
        setOpenMenus((prevOpenMenus) => ({
            ...Object.keys(prevOpenMenus).reduce((acc, key) => {
                acc[key] = key === menu ? !prevOpenMenus[menu] : false; // Close other menus and toggle the clicked menu
                return acc;
            }, {}),
        }));
        setActiveMenu(menu);
    };

    // Function to handle sidebar toggle
    const handleSidebarToggle = () => {
        setSidebarOpen((prevState) => !prevState);
    };

    // Effect to set active menu based on route change
    useEffect(() => {
        const path = location.pathname;
        const pathMap = {
            '/Home': 'Home',
            '/Files': 'FilesPage',
            '/AddFiles': 'AddFilesPage',
            '/QrCode': 'QRCode',
            '/AddQrCode': 'QRCode',
            '/ShortLink': 'ShortLink',
            '/TextDetect': 'TextDetect',
            '/FaceDetect': 'FaceDetect',
            '/ChangePassword': 'ChangePassword',
            '/nested': 'FilesPage',
            '/UserProfile':'UserProfile'

        };
        console.log("counter value is", count)
        const menu = Object.keys(pathMap).find(key => path.startsWith(key));
        const activeMenu = menu ? pathMap[menu] : null;

        setActiveMenu(activeMenu);
        setOpenMenus(activeMenu ? { [activeMenu]: true } : {});
        setSidebarOpen(true); // Ensure the sidebar is open when navigating to a new page
    }, [location.pathname]);

    // Ensure sidebar is open on initial render
    useEffect(() => {
        setSidebarOpen(true);
    }, []);
    // Anurag's code
    //Code to handle Log out
    const handleLogout = () => {
        console.log("Logout button clicked");
        axios.get(`${apiUrl}logout-user`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json', // Example: setting content type
                // Add other headers if required
            }
        })
            .then(res => {
                showToast('success','You have logged out successfully!')
                console.log("Response received:", res.data);

                navigate('/')
                sessionStorage.clear()
            })
            .catch(error => {
                showToast('error',`There's an error while logging out!`);
                console.log(error.response.data.error)
            });
    };
    const toast = useToast();


  const showToast = (status, message) => {
    toast({
      title: `${status.charAt(0).toUpperCase() + status.slice(1)}`,
      description: message,
      status: status, // Set this to 'error' for a red-colored pop-up
      duration: 3000,
      isClosable: true,
    });
  };
    return (
        <>
            <nav className={`sidebar sidebar-offcanvas ${sidebarOpen ? 'open' : 'closed'}`} id="sidebar">
                <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
                    <Link className="sidebar-brand brand-logo" to="/Files"><img src={LogoStolity} alt="logo" /></Link>
                    <Link className="sidebar-brand brand-logo-mini" to="/Files"><img src={LogoStolityMini} alt="logo" /></Link>
                </div>

                <div className='left_navigation_row'>
                    <ul className="nav">
                        {/*<li className={`nav-item menu-items ${activeMenu === 'Home' ? 'active' : ''}`}>
                        <Link className="nav-link ripple_effect" to="/Home" onClick={() => setSidebarOpen(true)}>
                            <span className="menu-icon">
                                <i className="icon-dashboard" />
                            </span>
                            <span className="menu-title">Dashboard</span>
                        </Link>
                    </li>*/}
                        {/* <li className={`nav-item menu-items ${activeMenu === 'FilesPage' ? 'active' : ''}`}>
                        <a
                            className="nav-link ripple_effect collapsed"
                            data-toggle="collapse"
                            href="#FilesPage"
                            aria-expanded={openMenus['FilesPage'] ? 'true' : 'false'}
                            onClick={() => handleMenuClick('FilesPage')}
                        >
                            <span className="menu-icon">
                                <i className="icon-files" />
                            </span>
                            <span className="menu-title">Files List</span>
                            <i className={`menu-arrow ${openMenus['FilesPage'] ? 'rotate' : ''}`} />
                        </a>
                    </li> */}

                        <li className={`nav-item menu-items ${activeMenu === 'FilesPage' ? 'active' : ''}`}>
                            <Link className="nav-link ripple_effect" to="/Files" onClick={() => setSidebarOpen(true)}>
                                <span className="menu-icon">
                                    <i className="icon-files" />
                                </span>
                                <span className="menu-title">Files List</span>
                            </Link>
                        </li>

                       <li className={`nav-item menu-items ${activeMenu === 'UserProfile' ? 'active' : ''}`}>
                            <Link className="nav-link ripple_effect" to="/UserProfile" onClick={() => setSidebarOpen(true)}>
                                <span className="menu-icon">
                                    <i className="mdi mdi-account" />
                                </span>
                                <span className="menu-title">Profile</span>
                            </Link>
                        </li>

                        
                        {/*<li className={`nav-item menu-items ${activeMenu === 'QRCode' ? 'active' : ''}`}>
                        <a
                            className="nav-link ripple_effect collapsed"
                            data-toggle="collapse"
                            href="#QRCode"
                            aria-expanded={openMenus['QRCode'] ? 'true' : 'false'}
                            onClick={() => handleMenuClick('QRCode')}
                        >
                            <span className="menu-icon">
                                <i className="icon-qrcode" />
                            </span>
                            <span className="menu-title">QR Code</span>
                            <i className={`menu-arrow ${openMenus['QRCode'] ? 'rotate' : ''}`} />
                        </a>
                        <div className={`collapse ${openMenus['QRCode'] ? 'show' : ''}`} id="QRCode">
                            <ul className="nav flex-column sub-menu">
                                <li className={`nav-item ${location.pathname === '/QrCode' ? 'active' : ''}`}>
                                    <Link className="nav-link ripple_effect" to="/QrCode" onClick={() => setSidebarOpen(true)}>QR Code List</Link>
                                </li>
                                <li className={`nav-item ${location.pathname === '/AddQrCode' ? 'active' : ''}`}>
                                    <Link className="nav-link ripple_effect" to="/AddQrCode" onClick={() => setSidebarOpen(true)}>Add QR Code</Link>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li className={`nav-item menu-items ${activeMenu === 'ShortLink' ? 'active' : ''}`}>
                        <Link className="nav-link ripple_effect" to="/ShortLink" onClick={() => setSidebarOpen(true)}>
                            <span className="menu-icon">
                                <i className="icon-link1" />
                            </span>
                            <span className="menu-title">Short Link</span>
                        </Link>
                    </li>
                    <li className={`nav-item menu-items ${activeMenu === 'TextDetect' ? 'active' : ''}`}>
                        <Link className="nav-link ripple_effect" to="/TextDetect" onClick={() => setSidebarOpen(true)}>
                            <span className="menu-icon">
                                <i className="icon-text-detect" />
                            </span>
                            <span className="menu-title">Text Detect</span>
                        </Link>
                    </li>
                   
                    <li className={`nav-item menu-items ${activeMenu === 'FaceDetect' ? 'active' : ''}`}>
                        <Link className="nav-link ripple_effect" to="/FaceDetect" onClick={() => setSidebarOpen(true)}>
                            <span className="menu-icon">
                                <i className="icon-face-detect" />
                            </span>
                            <span className="menu-title">Face Detect</span>
                        </Link>
                    </li> */}
                        <li className={`nav-item menu-items ${activeMenu === 'ChangePassword' ? 'active' : ''}`}>
                            <Link className="nav-link ripple_effect" to="/ChangePassword" onClick={() => setSidebarOpen(true)}>
                                <span className="menu-icon">
                                    <i className="icon-change-password" />
                                </span>
                                <span className="menu-title">Change Password</span>
                            </Link>
                        </li>

                    </ul>
                    <div className="footer_left">
                        <button className="logout_button ripple_effect" onClick={handleLogout}>
                            <i className="icon-logout" />
                            <span className="menu-title">Logout</span>
                        </button>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default SideNav;
