import React, { useContext, useState, useEffect } from 'react';
import noFolderLogo from "../images/sad.png"
import folderLogo from "../images/folderLogo.png"
import { UploadContext } from './UploadContext';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { addFolder, incrementCounter, incrementFCounter, removeLastFolder, decrementFCounter } from '../store/fileSlicer'
import axios from "axios"
import { ChakraProvider,  Stack, useToast } from '@chakra-ui/react';

const MoveFilePopup = ({ moveKey, source, onClose, files,folders }) => {
  const { addUpload, updateUploadProgress, removeUpload } = useContext(UploadContext);
  const [isRadioChecked, setIsRadioChecked] = useState(false);

  const apiUrl = process.env.REACT_APP_API_ENDPOINT;
  const token = sessionStorage.getItem("number");
  const folderList = useSelector((state) => state.getdata.folderList)
  const counter = useSelector((state) => state.getdata.folderCounter)
  const dispatch = useDispatch()
  const sourceFol = source.replace(/\/$/, '')
  console.log("soruce", sourceFol);
  console.log("folder list is",folderList,"index is",counter)


  const [selectedPath, setSelectedPath] = useState(null);






  function getTextAfterSlashes(text, counter) {
    if (counter <= 1) {
      return text;
    }


    const parts = text.split('/');


    if (parts.length <= counter - 1) {
      return '';
    }

    return parts.slice(counter - 1).join('/');
  }

  function getTextAfterLastSlash(text) {

    const parts = text.split('/');


    return parts[parts.length - 1];
  }
  const key = getTextAfterLastSlash(moveKey);
  const openingActivities = () => {

  };

  useEffect(() => {
    openingActivities();
  }, []);
  const getLastSegment = (path) => {
    // Split the path by slashes
    const parts = path.split('/');

    // Return the last segment
    return parts[parts.length - 1];
  };

  const lastSegments = files.map(getLastSegment);

  const handleItemClick = async (path) => {
    console.log(path)
    const res = await axios.get(`${apiUrl}getFolder`, {
      params: {
        folderPath: path,
      },
      headers: {

        'Authorization': `Bearer ${token}`,

      }
    })
    console.log("Response data is", res.data)
    const folders = res.data.filter(item => item?.isFolder === true || item?.isFolder === "true");
    let arr = [folders]

    dispatch(addFolder(arr))
    dispatch(incrementFCounter())

  };

  const handleMove = async () => {

    console.log("Material is", selectedPath, "key", key, "sourceFol", sourceFol,lastSegments,folders)
    try {
      if (folders && folders.length > 0) {
        const movingFolders = folders.join(', ');
        const uploadId = Date.now()
        addUpload(uploadId, "Moving "+movingFolders);
        const folderMoveRes = await axios.post(
          `${apiUrl}move-folder`,
          {
            "sourceFolders":folders, 
            "destinationFolder": selectedPath
          },
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            onUploadProgress: (progressEvent) => {
                const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                updateUploadProgress(uploadId, progress);
            }
          }
        );
        removeUpload(uploadId)
        console.log("Folders moved successfully:", folderMoveRes.data);
        showToast("success","Folders Moved Successfully!");
      }

      if (lastSegments && lastSegments.length > 0) {
        const nonEmptySegments = lastSegments.filter(segment => segment !== "");

    if (nonEmptySegments.length === 0) {
        console.warn("No segments to upload.");
        return; // Exit early if there are no non-empty segments
    }

    const uploadPromises = nonEmptySegments.map(async (segment) => {
        const uploadId = Date.now() + Math.random(); // Ensures a unique uploadId for each file
        const movingFile = segment;
        addUpload(uploadId, "Moving " + movingFile);
    
            try {
               console.log("segment",segment,lastSegments)
                const res = await axios.post(
                    `${apiUrl}move-file`,
                    {
                        sourceFolder: sourceFol,
                        destinationFolder: selectedPath,
                        keys: [segment], // Handle each file (segment) separately
                    },
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                        onUploadProgress: (progressEvent) => {
                            const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                            updateUploadProgress(uploadId, progress);
                        },
                    }
                );
                console.log(`File "${movingFile}" moved successfully:`, res.data);
                showToast("success", `File "${movingFile}" Moved Successfully!`);
            } catch (error) {
                console.error(`Error moving file "${movingFile}":`, error);
                showToast("error", `Failed to move file "${movingFile}". Please try again.`);
            } finally {
                removeUpload(uploadId); // Ensure the upload is removed regardless of success or failure
            }
        });
    
        // Wait for all uploads to complete before proceeding
        await Promise.all(uploadPromises);
    }
    
      if(key){
        const uploadId = Date.now()
        addUpload(uploadId, "Moving "+key);
        console.log("File to move is",key)
        const res = await axios.post(
          `${apiUrl}move-file`,
          {
            sourceFolder: sourceFol,
            destinationFolder: selectedPath,
            keys:  [key]
          },
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            onUploadProgress: (progressEvent) => {
              // Simulating progress for folder move
              const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              updateUploadProgress(uploadId, progress);
            }
          }
        );
        removeUpload(uploadId)
        console.log("File moved successfully:", res.data);
        showToast("success","File Moved Successfully!");
      }
      
      else {
        console.log("No files to move");
      }
  
      



      

    } catch (error) {
      showToast("error","Failed to move!")
      console.error("Error moving file:", error);
    } finally {
      onClose();
    }

  };

  const handleBack = () => {
    if (counter == 1) {

    } else {
      setIsRadioChecked(false);
      dispatch(removeLastFolder())
      dispatch(decrementFCounter())
    }


  }
  
  const toast = useToast();


  const showToast = (status, message) => {
    toast({
      title: `${status.charAt(0).toUpperCase() + status.slice(1)}`,
      description: message,
      status: status, // Set this to 'error' for a red-colored pop-up
      duration: 3000,
      isClosable: true,
    });
  };


  return (
    <>
    <ChakraProvider>
                
    </ChakraProvider>
    <div className="popup-container">
      <div className="popup">
        {counter == 1 ? <div></div> : <button className="back_button" onClick={handleBack}><i className='icon-arrow-dropdown'></i>Back</button>}
        <button className="close-button" onClick={onClose}>×</button>
        <ul className="radio_checkbox_listX" style={{ gap: 0 }}>{counter == 1 ? <li>

          <input
            className="form_check_radio"
            type="radio"
            name="destination"
            onClick={(e) => {
              e.stopPropagation();
              setIsRadioChecked(true);
              setSelectedPath("");
            }}
          />
          <span>{"ROOT FOLDER"}</span>
        </li> : ""}


        {folderList[counter - 1] && folderList[counter - 1].length > 0 ? (
  folderList[counter - 1].map((item, index) => (
    <li key={index} onClick={() => handleItemClick(item.fileName)}>
      <input
        className="form_check_radio"
        type="radio"
        name="destination"
        value={item.fileName}
        onClick={(e) => {
          e.stopPropagation();
          setSelectedPath(item.fileName);
          setIsRadioChecked(true);
        }}
      />
      <img
      style={{width:"18px",marginRight:'5px'}}
    src={folderLogo} 
    alt="Folder Logo"
    className="folder-logo" 
  />
      <span>{getTextAfterSlashes(item.fileName, counter)}</span>
    </li>
  ))
) : (
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
  <img src={noFolderLogo} alt="No folder to show" style={{ width: '50px', marginBottom: '10px' }} />
  <p>No folders to show</p>
</div>
)}

        </ul>
        <button
        
          className={`btn_gradient btn_width_same btn_full_width btn_red_ripple ripple_effect ${!isRadioChecked ? 'disabled' : ''}`}
          onClick={handleMove}
          disabled={!isRadioChecked} // Disable the button if no radio button is checked
        >
          Move
        </button>
      </div>
    </div>
    </>
  );
};

export default MoveFilePopup;


