import React, { useContext, useState, useEffect, useRef, useCallback } from 'react';
import Logo from '../images/logo.png';
import Select from 'react-select';

import fullscreeen from '../images/fullscreen.png'
import zoomin from '../images/zoomin.png'
import zoomout from '../images/zoomout.png'
import IconZoomIn from '../images/IconZoomIn.svg'
import IconZoomOut from '../images/IconZoomOut.svg'
import IconFullScreen from '../images/IconFullScreen.svg'
import IconDelete from '../images/IconDelete.svg'
import moveIcon from '../images/move.png'
import deleteIcon from '../images/trash.png'
import IconJPG from '../images/icon-jpg.svg';
import IconPNG from '../images/icon-png.svg';
import IconPSD from '../images/icon-psd.svg';
import IconPDF from '../images/pdf.svg';
import IconFolder from '../images/folder.png';
import IconJfif from '../images/jfif.png';
import IconMsi from '../images/msi.png';
import IconZip from '../images/zip.png';
import IconJpeg from '../images/jpeg.png';
import IconVideo from '../images/video.svg';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
import { Tooltip, Whisper, SelectPicker, Dropdown, Modal, Popover, Placeholder, Button } from 'rsuite';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Dropzone from 'react-dropzone';
import 'rsuite/Tooltip/styles/index.css';
import "rsuite/SelectPicker/styles/index.css";
import 'rsuite/dist/rsuite.min.css';
import { useDropzone } from 'react-dropzone';
import SideNav from '../components/SideNav';
import Footer from '../components/Footer';
import ToggleNav from '../components/ToggleNav';
import IconUpload from '../images/iconUpload.svg';
import { background, ChakraProvider, position, Stack, useToast } from '@chakra-ui/react';
import { UploadContext } from './UploadContext';
import { Modal as BootstrapModal } from 'react-bootstrap';
//LIGHTBOX
import Lightbox from "yet-another-react-lightbox";
import MoveFilePopup from './MoveFilePopup';
import MoveFolderPopup from './MoveFolderPopup'
import CopyFilePopup from './CopyFilePopup';
import "yet-another-react-lightbox/styles.css";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Video from 'yet-another-react-lightbox/plugins/video';
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/captions.css";
import ReactVideoThumbnail from 'react-video-thumbnail';
import axios from "axios"
import { useLocation, useNavigate, useNavigationType } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'

//Anurag Imports
import { addToken, incrementCounter, removeLastToken, decrementCounter, setFolderPath, addFolder, incrementFCounter, replacelasttoken, breadCrum, resetFolderList } from '../store/fileSlicer'
import { px } from 'framer-motion';





const NestedPage = () => {
  const { addUpload, updateUploadProgress, removeUpload } = useContext(UploadContext);
  //Anurag Declaration
  const apiUrl = process.env.REACT_APP_API_ENDPOINT;
  const [selectedFilter, setSelectedFilter] = useState('Sorting');
  const token = sessionStorage.getItem("number");
  const [errorMessage2, setErrorMessage2] = useState("");
  const [paginatedData, setPaginatedData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(10);
  const [totalPages, settotalPages] = useState(0)
  const [filedata, setFileData] = useState([])
  const [filedata2, setFileData2] = useState([])
  const dispatch = useDispatch();
  const [onlyfolderlist, setOnlyFolderList] = useState([])
  const [rootsize, setRootSize] = useState('')
  const [currentFile, setCurrentFile] = useState({});
  const [isProgressVisible, setIsProgressVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showFTPopup, setShowFTPopup] = useState(false);
  const [imageSrc, setImageSrc] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [videoSrc, setVideoSrc] = useState('');
  const [isVideo, setisVideo] = useState(false)
  const [newFileName, setNewFileName] = useState('');
  const counter = useSelector((state) => state.getdata.counter)
  const select = useSelector((state) => state.getdata.userdata);
  const selectedItem = select.find(item => item.id === counter);
  const nav = useNavigate()
  const navigate = useNavigate()
  const responseData = selectedItem ? selectedItem.Files : [];
  const [pdfSrc, setPdfSrc] = useState('')
  const [pubpri2, setPubPri2] = useState("private")
  const [pubpri3, setPubPri3] = useState("private")
  const newPath = useSelector((state) => state.getdata.folderName)
  const [query, setQuery] = useState('')
  const [folderList, setFolderList] = useState([])
  const [nameOfFolder, setNameOfFolder] = useState('')
  const [isVisibility, setIsVisibility] = useState(false)
  const [visiKey, setVisiKey] = useState("")
  const [infoShower, setInfoShower] = useState(false)
  const [modalFile, setModalFile] = useState("")
  const [folderFieldError, setFolderFieldError] = useState('');

  const [dragPop, setDragPop] = useState(false)
  const [dragFile, setDragFile] = useState({})
  const [targetFolder, setTargetFolder] = useState("")


  const [fileInfo, setFileInfo] = useState({
    fileName: '',
    fileSize: '',
    fileType: '',
    fileUrl: '',
    uploadDateTime: ''
  });
  const [show, setShow] = useState(false);
  const [showImage, setShowImage] = useState(false)

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const handleImageShow = () => setShowImage(true)
  const handleImageClose = () => {
    console.log("close button clicked!")
    setShowImage(false)
    setCurrentImageIndex(0)
    setImageSrc("")
    setVideoSrc("")
    setAudioSrc("")
    setPdfSrc("")

  }
  const location = useLocation();
  const value = location.state?.value;

  const [downloadPopup, setDownloadpopup] = useState(false)
  const [downloadLink, setDownloadLink] = useState(null)

  const [sharePopup, setSharepopup] = useState(false)
  const [shareLink, setShareLink] = useState("")

  const [keys, setKeys] = useState([]);
  const [keys2, setKeys2] = useState([])
  const [selectStatus, setSelectStatus] = useState(false)

  console.log("responseData", responseData)
  console.log("select", select)



  //Anurag Code
  const navigationType = useNavigationType();
  const location2 = useLocation();
  const previousLocationRef = useRef(location2);

  const [isWhisperClicked, setIsWhisperClicked] = useState(false);
  const [movedFile, setMovedFile] = useState("")

  const [isCWhisperClicked, setIsCWhisperClicked] = useState(false);
  const [copiedFile, setCopiedFile] = useState("")

  const [audioSrc, setAudioSrc] = useState('');
  const [isAudio, setIsAudio] = useState(false)

  const [moveFol, setMoveFol] = useState(false)
  const [movedFol, setMovedFol] = useState("")
  const fileTypes = ['pdf', 'jpg', 'jpeg', 'png', 'mov', 'mp3', 'mp4', 'webp'];
  const [selectedFileTypes, setSelectedFileTypes] = useState([]);

  const handleMClick = (name) => {
    setIsWhisperClicked(true);
    setMovedFile(name)

  };

  const handleMClose = () => {
    setIsWhisperClicked(!isWhisperClicked);
    setKeys([])
    setKeys2([])
    dispatch(resetFolderList())

  };
  const handleMFClick = (name) => {
    setMoveFol(true);
    setMovedFol(name)


  };
  const handleMFClose = () => {
    setMoveFol(false);
    dispatch(resetFolderList())
  };
  // Copy text to clipboard
  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareLink)
      .then(() => {
        showToast('success', 'Copied to clipboard!');
        setSharepopup(false)
      })
      .catch((err) => {
        showToast('error', 'Failed to copy!');
      });
  };
  // Copy File code
  const handleCClick = (name) => {
    setIsCWhisperClicked(true);
    setCopiedFile(name)
  };
  const handleCClose = () => {
    dispatch(resetFolderList())
    setIsCWhisperClicked(false);
    setKeys([])
  };
  //Checkbox code 
  const handleCheckboxChange = (file) => {
    if (file.isFolder === true) {
      // If the file is a folder, update the keys2 list
      setKeys2((prevKeys2) => {
        const isChecked = prevKeys2.includes(file.fileName);

        // Update the keys2 list for folders
        const newKeys2 = isChecked
          ? prevKeys2.filter((f) => f !== file.fileName)
          : [...prevKeys2, file.fileName];

        return newKeys2;
      });
    } else {
      // If the file is not a folder, update the keys list
      setKeys((prevKeys) => {
        const isChecked = prevKeys.includes(file.fileName);

        // Update the keys list for files
        const newKeys = isChecked
          ? prevKeys.filter((f) => f !== file.fileName)
          : [...prevKeys, file.fileName];

        return newKeys;
      });
    }
  };
  //Breadcrum Implementation
  const handleBreadClick = (event, part, index, len) => {
    // Prevent default behavior of <a> tag
    event.preventDefault();

    console.log("jbjb", index, len);

    if (len === index + 2) {
      console.log("Do nothing");
    } else {
      console.log(`You clicked on: ${part} and ${index}`);
      dispatch(breadCrum({ number: index }));

      const navId = counter - (index + 1);
      console.log("airtel", navId);

      navigate(-navId);
    }
  };
  const reduksData = useSelector((state) => state.getdata.userdata);
  console.log("reduksData", reduksData)

  const [isSelectAll, setIsSelectAll] = useState(false);
  // Handle select/deselect all
  const handleSelectAllToggle = () => {
    if (!isSelectAll) {
      // Select all - preserve existing selections and add all other items
      const allFiles = paginatedData
        .filter(file => !file.isFolder)
        .map(file => file.fileName);
      const allFolders = paginatedData
        .filter(file => file.isFolder)
        .map(file => file.fileName);

      setKeys(prevKeys => {
        const newKeys = [...new Set([...prevKeys, ...allFiles])];
        return newKeys;
      });

      setKeys2(prevKeys2 => {
        const newKeys2 = [...new Set([...prevKeys2, ...allFolders])];
        return newKeys2;
      });

    } else {
      // Deselect all
      setKeys([]);
      setKeys2([]);
      console.log("arrays are", keys, keys2)
    }
    setIsSelectAll(!isSelectAll);
  };
  useEffect(() => {
    console.log("Current arrays are:", keys, keys2);
  }, [keys, keys2]);



  const handleMulDelete = async () => {
    try {
      console.log(keys, keys2)
      // Perform delete for files if keys have items
      if (keys.length > 0) {
        const res = await axios.delete(`${apiUrl}delete-file`, {
          data: { keys: keys },
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        });
        console.log("Files deleted:", res.data);
        showToast('success', 'Files deleted Successfully!')
      }

      // Perform delete for folders if keys2 have items
      if (keys2.length > 0) {
        const resFolders = await axios.delete(`${apiUrl}delete-folder`, {
          data: { folderName: keys2 }, // Send keys for folders
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        });
        console.log("Folders deleted:", resFolders.data);
        showToast('success', 'Folder deleted Successfully!')
      }
      setIsSelectAll(false)
      setSelectStatus(false)
      reloadAfterTast()
      setKeys([])
      setKeys([]);
      setKeys2([]);

    } catch (error) {
      showToast("error", "Some error has occurred");
    }






  };

  useEffect(() => {
    reloadAfterTast()
  }, [isWhisperClicked, moveFol])

  useEffect(() => {

    console.log("Value of counter is", counter)
    setFilteredFileData(responseData, setFileData, counter);

  }, [counter]);



  const setFilteredFileData = (responseData, setFileData) => {
    const newRecords = responseData.length > 0 ? [...responseData] : [];

    setFileData(newRecords);
    settotalPages(Math.ceil(responseData.length / endIndex))
    const totalEntries = responseData.length;

  };
  useEffect(() => {
    const itemsPerPage = endIndex; // Number of items per page is determined by endIndex
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endSliceIndex = startIndex + itemsPerPage;

    setPaginatedData(filedata.slice(startIndex, endSliceIndex));
  }, [filedata, currentPage, endIndex]);
  const getFileTypeIcon = (fileType) => {

    switch (fileType) {
      case 'zip':
        return IconZip;
      case 'jfif':
        return IconJfif;
      case 'jpg':
        return IconJPG;
      case 'png':
        return IconPNG;
      case 'msi':
        return IconMsi;
      case 'jpeg':
        return IconJpeg;
      case 'pdf':
        return IconPDF;
      default:
        return IconFolder;
    }
  };

  const applyFilter = async () => {
    console.log('Selected file types for filter:', selectedFileTypes);
    console.log(path)
    try {
      console.log("Folders are loading...")

      const params = {

      };

      if (selectedFileTypes.length > 0) {
        params.fileTypes = selectedFileTypes.join(',');
        params.page = 1;
        params.folderPath = path.replace(/\/$/, '')
      } else {
        console.log("Empty fileTypes, setting page to 10");
        params.page = 1;
        params.folderPath = path.replace(/\/$/, '')
      }

      const response = await axios.get(`${apiUrl}getFolder`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        params: params,
      });
      console.log(response);
      if (selectedFileTypes.length > 0) {
        settotalPages(1)
        setPaginatedData(response.data)
        console.log("Filtered files are...", response.data)


      } else {

        setPaginatedData(response.data.result)
        console.log("Filtered files are...", response.data.result)
      }






    } catch (error) {
      console.error(`There's an error: ${error}`);
    }

    closePopup();
  };

  const handleFTCheckboxChange = (fileType) => {
    setSelectedFileTypes((prevSelected) =>
      prevSelected.includes(fileType)
        ? prevSelected.filter((type) => type !== fileType) // Remove if already selected
        : [...prevSelected, fileType] // Add if not selected
    );
  };

  const handleFTypeSelect = (eventKey) => {
    console.log("Filter selected !", eventKey)
    if (eventKey === "File Type") {
      console.log("Something...")
      setShowFTPopup(true)
    }
  }

  const handleFilterSelect = (eventKey) => {
    console.log("Filter selected !", eventKey)
    if (eventKey === "name-filter1") {
      setSelectedFilter('By Name(A-Z)')
      nameFilter1()
    }
    else if (eventKey === "name-filter2") {
      setSelectedFilter('By Name(Z-A)')
      nameFilter2()
    }
    else if (eventKey === "size-filter1") {
      setSelectedFilter('By Size(Ascending)')
      sizeFilter1()
    }
    else if (eventKey === "size-filter2") {
      setSelectedFilter('By Size(Descending)')
      sizeFilter2()
    }
    else if (eventKey === "date-filter1") {
      setSelectedFilter('By Date(Oldest)')
      dateFilter1()
    }
    else if (eventKey === "date-filter2") {
      setSelectedFilter('By Date(Newest)')
      dateFilter2()
    }
  }

  const nameFilter1 = async () => {
    try {
      const res = await axios.get(`${apiUrl}getFolder`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        params: {
          ascending: true,
          folderPath: path.replace(/\/$/, '')
        },
      })
      setPaginatedData(res.data)
      settotalPages(1)
    }
    catch (error) {
      console.error(`There's error at ${error}`)
    }
  }
  const nameFilter2 = async () => {
    try {
      const res = await axios.get(`${apiUrl}getFolder`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        params: {
          ascending: false,
          folderPath: path.replace(/\/$/, '')
        },
      })
      setPaginatedData(res.data)
      settotalPages(1)
    }
    catch (error) {
      console.error(`There's error at ${error}`)
    }
  }

  const sizeFilter1 = async () => {
    try {
      const res = await axios.get(`${apiUrl}getFolder`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        params: {
          sortSize: true,
          folderPath: path.replace(/\/$/, '')
        },
      })
      setPaginatedData(res.data)
      settotalPages(1)
    }
    catch (error) {
      console.error(`There's error at ${error}`)
    }
  }
  const sizeFilter2 = async () => {
    try {
      const res = await axios.get(`${apiUrl}getFolder`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        params: {
          sortSize: false,
          folderPath: path.replace(/\/$/, '')
        },
      })
      setPaginatedData(res.data)
      settotalPages(1)
    }
    catch (error) {
      console.error(`There's error at ${error}`)
    }
  }

  const dateFilter1 = async () => {
    try {
      const res = await axios.get(`${apiUrl}getFolder`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        params: {
          sortByDate: "asc",
          folderPath: path.replace(/\/$/, '')
        },
      })
      setPaginatedData(res.data)
      settotalPages(1)
    }
    catch (error) {
      console.error(`There's error at ${error}`)
    }
  }
  const dateFilter2 = async () => {
    try {
      const res = await axios.get(`${apiUrl}getFolder`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        params: {
          sortByDate: "desc",
          folderPath: path.replace(/\/$/, '')
        },
      })
      setPaginatedData(res.data)
      settotalPages(1)
    }
    catch (error) {
      console.error(`There's error at ${error}`)
    }
  }

  const closePopup = () => setShowFTPopup(false);




  //Anurag Check Folder or File
  const chkFileorFolder = (file, size) => {
    if (file?.isFolder === "true" || file?.isFolder === true) {
      console.log("Its a folder.")
      dispatch(
        setFolderPath({
          folderPath: file.fileName + '/',
        })
      )
      getFolderFiles(file, size)
    }
    else {
      console.log("Its a file.")


      openFile(file)

    }
  }
  //Anurag get into folder
  const getFolderFiles = async (foldername, size) => {
    try {

      const cleanfoldername = foldername.fileName
      console.log("clean folder name", cleanfoldername)

      const res = await axios.get(`${apiUrl}getFolder`, {
        params: {
          folderPath: cleanfoldername
        },

        headers: {

          'Authorization': `Bearer ${token}`,
        }
      })
      console.log("Response data is", res.data)



      nav(`/nested/${counter + 1}`, { state: { value: size } })
      dispatch(
        addToken({
          id: counter + 1,
          Files: res.data

        })
      );
      dispatch(incrementCounter());
    } catch (error) {
      console.error(`There's error at ${error}`)
    }

  }


  const getLastSegment = (path) => {
    // Split the path by slashes
    const parts = path.split('/');

    // Return the last segment
    return parts[parts.length - 1];
  };

  function getFileNameWithoutExtension(fileName) {
    const lastDotIndex = fileName.lastIndexOf('.');

    if (lastDotIndex !== -1) {
      // If a dot is found, return the file name without the extension
      return fileName.substring(0, lastDotIndex);
    } else {
      // If no dot is found, return the entire name (assuming it's a folder)
      return fileName;
    }
  }

  const removeSlash2 = (filename) => {
    // Split the filename by slashes
    const parts = filename.split('/');

    // Check if there is at least one slash
    if (parts.length > 1) {
      // Join everything after the first slash
      return parts.slice(1).join('/');
    } else {
      // If there are no slashes, return the original filename
      return filename;
    }
  };
  //Anurag handle Lightbox close
  const handleLightboxClose = () => {
    setIsOpen(false)
    setisVideo('')
    setImageSrc('')
    setAudioSrc('')
    setIsAudio(false)
  }
  //Anurag extract first part of the file
  const extractFirstPart = (str) => {
    const index = str.indexOf('/');
    if (index === -1) {
      // Return the whole string if there's no slash
      return str;
    }
    return str.substring(0, index);
  };



  //Image getting function
  const getImageInfo = async (filename) => {
    try {
      const res = await axios.get(`${apiUrl}getFile`, {
        params: {
          filePath: filename,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'arraybuffer',
      });
      setIsProgressVisible(false)
      console.log("Image is clicked")
      const base64Image = arrayBufferToBase64(res.data);
      const fileType = res.headers['content-type'];
      setImageSrc(`data:${fileType};base64,${base64Image}`)
    }
    catch (error) {
      console.error(error);
    }
  }
  //Audio getting function
  const getAudioInfo = async (filename) => {
    try {
      const res = await axios.get(`${apiUrl}getFile`, {
        params: {
          filePath: filename,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'arraybuffer',
      });
      setIsProgressVisible(false)
      const fileType = res.headers['content-type'];
      const blob = new Blob([res.data], { type: fileType });
      const url = window.URL.createObjectURL(blob);
      setAudioSrc(url);

    }
    catch (error) {
      console.error(error);
    }
  }

  //Pdf getting function
  const getPdfInfo = async (filename) => {
    try {
      const res = await axios.get(`${apiUrl}getFile`, {
        params: {
          filePath: filename,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'arraybuffer',
      });
      setIsProgressVisible(false)
      const fileType = res.headers['content-type'];
      const blob = new Blob([res.data], { type: fileType });
      const url = window.URL.createObjectURL(blob);
      setPdfSrc(url);

    }
    catch (error) {
      console.error(error);
    }
  }
  //Anurag View Image, Video
  const openFile = async (file) => {
    try {
      const res = await axios.get(`${apiUrl}getFile`, {
        params: {
          filePath: file.fileName,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'arraybuffer',
      });
      const exactFile = removeSlash2(file.fileName);
      const fileType = res.headers['content-type'];
      setCurrentFile(fileTypeExtractor(fileType))


      const blob = new Blob([res.data], { type: fileType });
      const url = window.URL.createObjectURL(blob);
      console.log(fileType)
      if (fileType === 'application/zip') {
        console.log('Handling zip...');

      } else if (extractFirstPart(fileType) === 'video') {
        console.log('Handling video...');
        if (fileType === 'video/mkv') {
          setErrorMessage('This video format is not supported by your browser.');
        } else {
          setIsOpen(!isOpen);
          setVideoSrc(url);
          setisVideo(true)

        }
      }
      else if (fileType === 'application/pdf') {
        console.log('Handling pdf...');
        setOpenPDFModal(true)
        setPdfSrc(url)
        console.log(pdfSrc)

      }
      else if (extractFirstPart(fileType) === 'image') {
        console.log('Handling image...');
        setIsOpen(!isOpen);
        const base64Image = arrayBufferToBase64(res.data);

        setImageSrc(`data:${fileType};base64,${base64Image}`)


      }

      else if (extractFirstPart(fileType) === 'audio') {

        setIsAudio(true);

        console.log('Handling audio...');
        setAudioSrc(url);

      }
      else if (extractFirstPart(fileType) === 'text') {
        console.log('Handling text file...')
        setOpenPDFModal(true)
        setPdfSrc(url)
        console.log(pdfSrc)
      }
    } catch (error) {
      console.error('Error fetching file:', error);
    } finally {

    }
  };
  function arrayBufferToBase64(buffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }
  const fileTypeExtractor = (file) => {
    const partBeforeSlash = file.split('/')[0];
    return partBeforeSlash
  }

  useEffect(() => {
    return () => {
      if (audioSrc) {
        URL.revokeObjectURL(audioSrc);
      }
    };
  }, [audioSrc]);
  const audioFalse = () => {
    setIsAudio(false)
    setAudioSrc("")
  }




  const data = ['10', '20', '50', '100'].map(
    item => ({ label: item, value: item }

    )

  );
  const pageFilter = (data) => {
    setEndIndex(data)
  }

  const [openFileUploadModal, setOpenFileUploadModal] = useState(false);
  const [createFolderButton, setCreateFolderButton] = useState(false)

  // Functions to handle modal visibility
  const handleOpenFileUploadModal = () => setOpenFileUploadModal(true);
  const handleCloseFileUploadModal = () => setOpenFileUploadModal(false);


  const handleOpenCreateFolder = () => setCreateFolderButton(true)
  const handleCloseCreateFolder = () => { setCreateFolderButton(false); setFolderFieldError("") }

  const [isLoading, setLoading] = useState(true); // State to manage loading state
  useEffect(() => {
    // Simulate an API call or data loading delay
    setTimeout(() => setLoading(false), 300); // Simulate 2 seconds loading time
  }, []);

  // INPUT VALUE 
  // INPUT VALUE 
  const [inputValue, setInputValue] = useState('');
  const handleInputChange = (e) => setInputValue(e.target.value);

  //POPOVER WITH TABLE ROW ACTIVE
  const [activeRow, setActiveRow] = useState(null);
  const [wholeFile, setWholeFile] = useState(null)

  const [renamePop, setRenamepop] = useState(false)
  const [extension, setExtension] = useState("")
  const handleOpenPopover = (file) => {
    console.log(file)

    setWholeFile(file)
    setNewFileName(file.fileName)
    setActiveRow(file.fileName);
    if (file.isFolder) {
      setInputValue(checkLastHash(file.fileName));
      console.log(checkLastHash(file.fileName))
    } else {
      setInputValue(checkLastHash(getFileNameWithoutExtension(file.fileName)));
      setExtension(file.fileName.substring(file.fileName.lastIndexOf('.')));

    }
    setRenamepop(true)
  };

  const handleClosePopover = () => {
    setActiveRow(null);
    setRenamepop(false)
  };

  function removeSlashAndExtension(filePath) {
    // Extract the filename by removing everything before the last slash
    const fileName = filePath.substring(filePath.lastIndexOf('/') + 1);

    // Remove the extension after the last dot
    return fileName;
  }



  //Check last /
  const checkLastHash = (name) => {
    // Remove trailing slash if present
    if (name.endsWith("/")) {
      name = name.slice(0, -1);
    }

    // Split the string by slashes and return the last part
    const parts = name.split("/");
    return parts[parts.length - 1]; // Return the text after the last slash
  };


  //Rename Api call
  const handleRadioChange = (event) => {
    setPubPri2(event.target.value);
  };
  const handleRadioChange2 = (event) => {
    setPubPri3(event.target.value);
  };
  const handleFileRename = async (oldkey, newkey) => {
    console.log("oldkey is", oldkey)
    console.log("newkwy is", newkey)
    console.log("Started to rename files, folders...")
    if (oldkey.includes(".")) {
      try {
        console.log("File")
        console.log(oldkey)
        console.log(newkey)
        const res = await axios.post(`${apiUrl}rename-file`,
          {
            "oldKey": oldkey,
            "newKey": newkey
          }, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        showToast('success', 'File renamed successfully!');
        setExtension("")
        reloadAfterTast()
        handleClosePopover()
      } catch (error) {
        showToast('error', `There's an error while renaming file!`);
      }
    }
    else {
      console.log("Folder")

      try {
        console.log(token)
        console.log(oldkey)
        console.log(newkey)
        const res = await axios.post(`${apiUrl}rename-folder`,
          {
            "oldFolderName": oldkey,
            "newFolderName": newkey
          }, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        showToast('success', 'Folder renamed successfully');
        reloadAfterTast()
        handleClosePopover()
      } catch (error) {
        showToast('error', `There's an error while renaming folder!`);
      }
    }


  };
  //Convert Visibility
  //Convert visibility
  const changeVisibility = (file) => {
    if (!pubpri2) {
      // Show toast or pop-up if no radio button is selected
      showToast("error", "Please select Public or Private before proceeding.");
      return;
    }


    const actualoperation = async () => {
      try {
        const res = await axios.post(
          `${apiUrl}convert-visibility`,
          {
            "key": file,
            "targetVisibility": pubpri2
          },
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          }
        );
        console.log(res.data);
        showToast("success", "Visibility has been changed!")
        setPubPri2("");
        setVisiKey("")
        setIsVisibility(false)
        reloadAfterTast()
      } catch (error) {
        console.error(`There's error at ${error}`);
        showToast("error", "Error while changing visibility!")
      }
    };

    actualoperation();
  };
  // DELETE POPOVER
  const [activeDeleteRow, setActiveDeleteRow] = useState(null);
  const [deletePop, setDeletepop] = useState(false)
  // Function to open delete popover
  const handleOpenDeletePopover = (id) => {
    setActiveDeleteRow(id);
    setDeletepop(true)
  };

  // Function to close delete popover
  const handleCloseDeletePopover = () => {
    setActiveDeleteRow(null);
    setDeletepop(false)
  };





  //Anurag  Delete File or Folder
  const handleFileDelete = async (file) => {
    console.log("Started to delete files, folder...");
    console.log(file);

    if (file?.isFolder == true) {
      console.log("folder", file);
      console.log(file);
      try {
        const res = await axios.delete(`${apiUrl}delete-folder`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          data: {
            folderName: [file.fileName],
          },
        });
        showToast('success', 'Folder deleted successfully!');
        reloadAfterTast()
        handleCloseDeletePopover()
      } catch (error) {
        showToast('error', `There's an error while deleting folder!`);
      }



    }

    else {
      console.log("file")
      console.log(file)
      const deleteKey = file.fileName;

      const dataToSend = {
        keys: [deleteKey] // This will correctly pass an array with the file name
      };

      try {
        const res = await axios.delete(`${apiUrl}delete-file`, {
          data: dataToSend,
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });
        showToast('success', 'File deleted successfully');
        reloadAfterTast()
        handleCloseDeletePopover()
      } catch (error) {
        showToast('error', `There's an error while deleting file!`);
      }

    }
  };

  //Remove data from redux after back button clicked
  useEffect(() => {
    if (location2.pathname !== previousLocationRef.current.pathname) {
      if (navigationType === 'POP') {
        // User clicked the back button
        console.log('Back button was clicked');
        // Perform your desired action here

        dispatch(decrementCounter());
        dispatch(removeLastToken());

      }
      previousLocationRef.current = location2;
    }
  }, [location2, navigationType]);
  //Navigate to Files page when user clicks reload button



  useEffect(() => {

    if (sessionStorage.getItem('reloaded')) {

      navigate('/Files');
    } else {

      sessionStorage.setItem('reloaded', 'true');
    }
    return () => {
      sessionStorage.removeItem('reloaded');
    };
  }, [navigate]);


  const [openPDFModal, setOpenPDFModal] = useState(false);
  const handleOpenPDFModal = () => setOpenPDFModal(true);
  const handleClosePDFModal = () => setOpenPDFModal(false);













  //Upload code starts here
  const [pubpri, setPubPri] = useState("private")
  const [files, setFiles] = useState([]);
  const [directory, setDirectory] = useState('')
  const [fileList, setFileList] = useState([])
  const [preLoader2, setPreLoader2] = useState(false)
  const [folderStructure, setFolderStructure] = useState({});
  const t = useSelector((state) => state.getdata.folderName)


  const getUcer = (file, counter) => {
    const parts = file.split('/');
    return parts.slice(0, counter).join('/') + '/';
  }

  if (counter > 0) {
    var path = getUcer(t, counter)
    console.log("path will be", path)

  } else {
    path = '';
  }
  const secondPath = path;
  console.log("secondPath", secondPath)
  const parts = secondPath.split("/");


  const removeFile = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  const handlePubChange = (event) => {
    setPubPri(event.target.value);
  };
  function getTextBeforeFirstSlash(text) {
    const indexOfFirstSlash = text.indexOf('/');
    if (indexOfFirstSlash !== -1) {
      return text.substring(0, indexOfFirstSlash);
    }
    return text; // Return the entire text if no slash is found
  }
  function getModifiedRecentFolderText(input) {
    const index = input.indexOf('/');
    if (index !== -1 && index < input.length - 1) {
      // Extract the substring after the first slash
      const textAfterSlash = input.substring(index + 1);
      // Replace all '/' with '<'
      return textAfterSlash.replace(/\//g, '<');
    } else {
      return "";
    }
  }


  const fileList2 = files.map((file, index) => (
    <li key={file.name}>
      <div>
        {file.type.startsWith('image/') ? (
          <div className='file_img'>
            <img
              src={URL.createObjectURL(file)}
              alt={file.name}
              style={{}}
            />
          </div>
        ) : (
          <div>
            <i className="mdi mdi-file-document-box-multiple-outline" style={{ fontSize: '48px' }}></i>
          </div>
        )}
        <div className='upload_file_content'>
          <button onClick={() => removeFile(index)}><i className='mdi mdi-close'></i></button>
        </div>
      </div>
    </li>
  ));

  //Upload File Code 
  const handleFileUpload = async () => {
    if (files.length === 0) {
      showToast("success", "Please select a file to upload.");
      return;
    }

    setOpenFileUploadModal(false);
    setPreLoader2(true);

    // Array to hold all file upload promises
    const uploadPromises = files.map((file, i) => {
      const fileName = file.name;
      const uploadId = Date.now() + i; // Unique ID for each file
      addUpload(uploadId, "Uploading " + fileName);

      const formData = new FormData();
      formData.append('files', file);
      formData.append('isPrivate', pubpri);
      formData.append('folderPath', path);
      formData.append('storageClass', 'STANDARD');

      // Individual file upload request
      return axios.post(`${apiUrl}upload-file`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent) => {
          const totalLength = progressEvent.lengthComputable
            ? progressEvent.total
            : file.size;

          if (totalLength) {
            const progress = Math.round((progressEvent.loaded * 100) / totalLength);
            console.log('Upload Progress:', progress, '%');
            updateUploadProgress(uploadId, progress); // Update individual file progress
          }
        }
      })
        .then(response => {
          setPubPri("private")
          removeUpload(uploadId); // Remove the progress bar once completed
          reloadAfterTast(); // Reload data or perform post-upload action
        })
        .catch(error => {
          showToast('error', `Error uploading file: ${fileName}`);
        });
    });

    try {
      // Wait for all file uploads to complete
      await Promise.all(uploadPromises);
      showToast("success", "Files uploaded successfully!");
    } catch (error) {
      showToast('error', 'Error uploading files');
    } finally {
      setPreLoader2(false); // Hide loader after all uploads complete
      setFiles([]);
    }
  };


  //Anurag folder upload
  const handleFolderChange = (event) => {
    console.log("Event", event);
    const files = event;
    const updatedFileList = [];
    const updatedFolderStructure = {};

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const relativePath = file.webkitRelativePath;

      // Separate the folder path and file name
      const folderPath = relativePath.substring(0, relativePath.lastIndexOf('/'));
      setNameOfFolder(folderPath)
      // Update the file list with the folder path and file
      updatedFileList.push({
        path: folderPath, // Only the folder path without the file name
        file: file
      });

      // Update the folder structure without including the file name
      updatedFolderStructure[file.name] = folderPath;
    }

    setFileList(updatedFileList);
    setFolderStructure(updatedFolderStructure);

    if (updatedFileList.length > 0) {
      setDirectory(getTextBeforeFirstSlash(updatedFileList[0].path));
    }

    console.log('Updated file list:', updatedFileList);
    console.log('Folder structure:', updatedFolderStructure);
  };

  useEffect(() => {
    console.log("fileList", fileList);
  }, [fileList]);
  function removeLastSlashAndText(inputString) {
    const lastSlashIndex = inputString.lastIndexOf('/');

    // If no slash is found, return the original string
    if (lastSlashIndex === -1) {
      return inputString;
    }

    return inputString.substring(0, lastSlashIndex);
  }
  function removeAfterLastSlash(text) {
    const lastSlashIndex = text.lastIndexOf("/");
    if (lastSlashIndex === -1) {
      return text; // Return the original text if no slash is found
    }
    return text.substring(0, lastSlashIndex + 1); // +1 includes the slash
  }
  //Upload folder2
  const uploadFolder = async () => {
    try {
      const formData = new FormData();
      formData.append('folderStructure', JSON.stringify(folderStructure));
      formData.append('folderPath', `${removeLastSlashAndText(path)}`);
      formData.append('storageClass', 'STANDARD_IA');
      formData.append('isPrivate', pubpri3);

      fileList.forEach(fileInfo => {
        // Append the file to FormData with the correct path
        formData.append('files', fileInfo.file, `${fileInfo.path}/${fileInfo.file.name}`);
      });
      setOpenFileUploadModal(false)
      console.log("Form data is", formData);

      const uploadId = Date.now(); // Unique ID for the folder upload
      addUpload(uploadId, 'Uploading' + nameOfFolder);

      console.log("Folder upload started...");
      setPreLoader2(true);
      setPreLoader2(true);
      console.log("Folder structure being sent:", folderStructure);
      setFiles([])
      const response = await axios.post(`${apiUrl}upload-folder`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent) => {
          const totalLength = progressEvent.lengthComputable
            ? progressEvent.total
            : fileList.reduce((acc, fileInfo) => acc + fileInfo.file.size, 0); // Calculate total size of files in folder

          if (totalLength) {
            const progress = Math.round((progressEvent.loaded * 100) / totalLength);
            console.log('Folder Upload Progress:', progress, '%');
            updateUploadProgress(uploadId, progress); // Update progress in context
          }
        }
      });
      setPubPri3("private")
      removeUpload(uploadId);
      showToast("success", "Folder uploaded successfully!");
      setPreLoader2(false);
      console.log('Folder uploaded successfully:', response.data);
      reloadAfterTast()
    } catch (error) {
      showToast('error', 'Error uploading folder');
    }
  };


  useEffect(() => {
    console.log("fileList", fileList);
  }, [fileList]);
  //Create Folder
  const createJustFolder = async (event) => {
    event.preventDefault();
    const isValid = /^[a-zA-Z0-9_\- ]{1,}$/.test(document.getElementById('folname').value);
    if (!isValid) {
      setFolderFieldError("Folder name can only contain letters, numbers, underscores, hyphens, and spaces.")

      return;
    }
    const folderfield = document.getElementById('folname').value;
    if (folderfield) {
      const folderName = path + document.getElementById('folname').value;
      console.log(folderName)
      try {
        const res = await axios.post(`${apiUrl}create-folder`, { "folderName": folderName }, {

          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        })
        handleCloseCreateFolder()
        setOpenFileUploadModal(false)
        console.log(res.data)
        showToast('success', 'Folder created successfully!')
        reloadAfterTast()
      }
      catch (error) {
        console.error(`There's error at ${error}`)
      }
      document.getElementById('folname').value = null;

    }

  }






  //upload folder bug
  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);
    handleFolderChange(acceptedFiles);
  }, [handleFolderChange]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    // This will attempt to get all files from a folder
    directory: true,
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
  });

  const handleSelectFolder = () => {
    // Create an input element
    const input = document.createElement('input');
    input.type = 'file';
    input.webkitdirectory = true;
    input.directory = true;
    input.multiple = true;

    input.onchange = (e) => {
      const files = Array.from(e.target.files);
      setFiles(files);
      handleFolderChange(files);
    };

    // Trigger the file input click
    input.click();
  };



  const filterAndPaginateData = (response, path, query) => {
    const filteredRecords = response.filter(record => {
      const matchesPath = removeAfterLastSlash(record.fileName) === path;
      const matchesQuery = record.fileName.toLowerCase().includes(query.toLowerCase());
      return matchesPath && matchesQuery;
    });

    setPaginatedData(filteredRecords);
  };




  useEffect(() => {
    filterAndPaginateData(responseData, path, query);
  }, [query]);


  const reloadAfterTast = async () => {
    try {
      console.log("in reloadAfterTest")
      console.log("Path ars", path.replace(/\/$/, ''))
      const res = await axios.get(`${apiUrl}getFolder`, {
        params: {
          folderPath: path.replace(/\/$/, '')
        },

        headers: {

          'Authorization': `Bearer ${token}`,
        }
      })
      console.log("Response. data is", res.data)

      setFilteredFileData(res.data, setFileData)
      dispatch(
        replacelasttoken(
          {
            id: counter,
            Files: res.data
          }
        )
      )



    } catch (error) {
      console.error(`There's error at ${error}`)

      if (error.response && error.response.status === 500) {
        window.alert('Your session has expired. Please log in again.');
        sessionStorage.clear()
        nav('/')
      } else {
        console.error(`There's an error: ${error}`);
      }
    }
  }

  //Download File and Folder
  const downloadFile = (file) => {
    console.log("started to download...")
    setDownloadpopup(true)
    if (file?.isFolder == true) {
      console.log("Its a folder.")


    }
    else {
      console.log("Its a file.")
      try {
        const getDownLink = async () => {
          const res = await axios.get(`${apiUrl}getFile`, {
            params: {
              filePath: file.fileName
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
            responseType: 'arraybuffer',
          });
          const exactFile = removeSlash2(file.fileName);
          const fileType = res.headers['content-type'];
          const blob = new Blob([res.data], { type: fileType });
          const url = window.URL.createObjectURL(blob);
          console.log(fileType)

          setDownloadLink({ url, name: exactFile });

        }
        getDownLink()


      }
      catch (error) {
        console.error('Error fetching file:', error);
      }
    }
  }
  //File information shower
  const getFileInfo = async (name) => {
    try {
      const res = await axios.get(`${apiUrl}file-info`, {
        params: {
          filePath: name,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'application/json',
      })
      console.log("File information is", res.data)
      let fileData = res.data;

      // If res.data is a string, parse it as JSON
      if (typeof res.data === 'string') {
        fileData = JSON.parse(res.data);
      }

      console.log("File information is", fileData);
      setFileInfo({
        fileName: fileData.filePath,
        fileSize: fileData.fileSize,
        fileType: fileData.fileType,
        uploadDateTime: fileData.uploadDateTime,
        fileUrl: fileData.url,

      });
      console.log(res.data.filePath)
      console.log("File info", fileInfo)
    }
    catch (error) {
      console.log(error)
      showToast("error", "Unable to show information!")
    }
  }



  const shareFile = (file) => {
    console.log("started to download...")
    setSharepopup(true)
    if (file?.isFolder == true) {
      console.log("Its a folder.")


    }
    else {
      console.log("Its a file.", file.fileName)
      try {
        const getShareLink = async () => {
          const res = await axios.get(`${apiUrl}file-info`, {
            params: {
              filePath: file.fileName,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
            responseType: 'application/json',
          });

          const parsedData = JSON.parse(res.data); // Parse the JSON string into an object
          const url = parsedData.url; // Access the 'url' property
          setShareLink(url)

          console.log("res data", url)
        }
        getShareLink()
      }
      catch (error) {
        console.error('Error fetching file:', error);
      }
    }
  }

  const customTruncateFileName = (name, maxLength) => {
    if (name.length > maxLength) {
      return `${name.substring(0, maxLength - 9)} of the ${name.substring(maxLength - 6, maxLength)}...`;
    }
    return name;
  };

  const toast = useToast();


  const showToast = (status, message) => {
    toast({
      title: `${status.charAt(0).toUpperCase() + status.slice(1)}`,
      description: message,
      status: status, // Set this to 'error' for a red-colored pop-up
      duration: 3000,
      isClosable: true,
    });
  };


  //Image slider functionality
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);


  const zoomIn = () => setZoomLevel((prevZoom) => Math.min(prevZoom + 0.2, 3));
  const zoomOut = () => setZoomLevel((prevZoom) => Math.max(prevZoom - 0.2, 1));
  const toggleFullscreen = () => setIsFullscreen((prev) => !prev);

  const handleNext = () => {
    setErrorMessage2("")
    setIsProgressVisible(true)
    setCurrentImageIndex((prevIndex) => {
      let newIndex = (prevIndex + 1) % paginatedData.length;
      let fileType = paginatedData[newIndex].fileType;

      // Loop to find the next item that is a file (has a fileType)
      while (!fileType && paginatedData.length > 0) {
        newIndex = (newIndex + 1) % paginatedData.length;
        fileType = paginatedData[newIndex].fileType;
      }

      if (fileType === 'mp3' || fileType === 'MP3' || fileType === 'wav' || fileType === 'WAV' || fileType === 'ogg' || fileType === 'OGG' || fileType === 'aac' || fileType === 'AAC') {
        setImageSrc("")
        setVideoSrc("")
        setPdfSrc("")
        getAudioInfo(filedata[newIndex].fileName);
        setModalFile(filedata[newIndex].fileName)
      }


      else if (fileType === 'jpeg' || fileType === 'jpg' || fileType === 'png' || fileType === 'gif' || fileType === 'hevc' || fileType === 'heif' || fileType === 'JPEG' || fileType === 'JPG' || fileType === 'PNG' || fileType === 'GIF' || fileType === 'HEVC' || fileType === 'HEIF' || fileType === 'svg' || fileType === 'SVG' || fileType === 'webp' || fileType === 'WEBP') {
        setVideoSrc("")
        setAudioSrc("")
        setPdfSrc("")
        getImageInfo(filedata[newIndex].fileName);
        setModalFile(filedata[newIndex].fileName)
      }

      else if (fileType === 'pdf' || fileType === 'PDF' || fileType === 'txt' || fileType === 'TXT') {
        setImageSrc("")
        setVideoSrc("")
        setAudioSrc("")
        getPdfInfo(filedata[newIndex].fileName);
        setModalFile(filedata[newIndex].fileName)
      }

      else if (fileType === 'mkv' || fileType === 'mp4' || fileType === 'mov' || fileType === 'mpeg' || fileType === 'webm' || fileType === 'MOV') {
        setImageSrc("")
        setAudioSrc("")
        setPdfSrc("")
        setIsProgressVisible(false)
        setVideoSrc(filedata[newIndex].fileName);
        setModalFile(filedata[newIndex].fileName)
      }

      else {
        setImageSrc("")
        setAudioSrc("")
        setPdfSrc("")
        setVideoSrc("")
        setIsProgressVisible(false)
        setErrorMessage2("Unsupported file format");
        setModalFile(filedata[newIndex].fileName)
      }


      setZoomLevel(1);
      return newIndex;

    });
  };

  const handlePrev = () => {
    setErrorMessage2("")
    setIsProgressVisible(true)
    setCurrentImageIndex((prevIndex) => {
      let newIndex = (prevIndex - 1 + paginatedData.length) % paginatedData.length;
      let fileType = paginatedData[newIndex].fileType;

      // Loop to find the next item that is a file (has a fileType)
      while (!fileType && paginatedData.length > 0) {
        newIndex = (newIndex - 1 + paginatedData.length) % paginatedData.length;
        fileType = paginatedData[newIndex].fileType;
      }


      if (fileType === 'mp3' || fileType === 'MP3' || fileType === 'wav' || fileType === 'WAV' || fileType === 'ogg' || fileType === 'OGG' || fileType === 'aac' || fileType === 'AAC') {
        setImageSrc("")
        setVideoSrc("")
        setPdfSrc("")
        getAudioInfo(filedata[newIndex].fileName);
        setModalFile(filedata[newIndex].fileName)
      }


      else if (fileType === 'jpeg' || fileType === 'jpg' || fileType === 'png' || fileType === 'gif' || fileType === 'hevc' || fileType === 'heif' || fileType === 'JPEG' || fileType === 'JPG' || fileType === 'PNG' || fileType === 'GIF' || fileType === 'HEVC' || fileType === 'HEIF' || fileType === 'svg' || fileType === 'SVG' || fileType === 'webp' || fileType === 'WEBP') {
        setVideoSrc("")
        setAudioSrc("")
        setPdfSrc("")
        getImageInfo(filedata[newIndex].fileName);
        setModalFile(filedata[newIndex].fileName)
      }

      else if (fileType === 'pdf' || fileType === 'PDF' || fileType === 'txt' || fileType === 'TXT') {
        setImageSrc("")
        setVideoSrc("")
        setAudioSrc("")
        getPdfInfo(filedata[newIndex].fileName);
        setModalFile(filedata[newIndex].fileName)
      }

      else if (fileType === 'mkv' || fileType === 'mp4' || fileType === 'mov' || fileType === 'mpeg' || fileType === 'webm' || fileType === 'MOV') {
        setImageSrc("")
        setAudioSrc("")
        setPdfSrc("")
        setIsProgressVisible(false)
        setVideoSrc(filedata[newIndex].fileName);
        setModalFile(filedata[newIndex].fileName)
      }

      else {
        setImageSrc("")
        setAudioSrc("")
        setPdfSrc("")
        setVideoSrc("")
        setIsProgressVisible(false)
        setErrorMessage2("Unsupported file format");
        setModalFile(filedata[newIndex].fileName)
      }
      setZoomLevel(1);
      return newIndex;

    });
  };

  const deleteFromModal = async (filename) => {
    console.log("Deleting file", filename)
    const dataToSend = {
      keys: [filename]
    };
    try {
      const res = await axios.delete(`${apiUrl}delete-file`, {
        data: dataToSend,
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });

      async function executeFunctionsInOrder() {
        try {
          showToast('success', 'File deleted successfully');

          await reloadAfterTast();



          handleNext();
        } catch (error) {
          console.error('Error executing functions:', error);
        }
      }

      executeFunctionsInOrder();

    } catch (error) {
      showToast('error', `There's an error while deleting file!`);
    }
  }

  useEffect(() => {
    // Function to handle keydown events
    const handleKeyDown = (event) => {
      if (showImage) {
        if (event.key === "ArrowLeft") {
          handlePrev(); // Trigger handlePrev when the left arrow key is pressed
        } else if (event.key === "ArrowRight") {
          handleNext(); // Trigger handleNext when the right arrow key is pressed
        }
      }
    };

    // Add the event listener when the component mounts
    window.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [showImage]);




  //Drag to move 
  const [draggedItem, setDraggedItem] = useState(null);

  // Function called when dragging starts
  const handleDragStart = (e, file) => {
    setDraggedItem(file);  // Keep track of the currently dragged item
    e.dataTransfer.effectAllowed = "move";

    const totalSelectedItems = keys.length + keys2.length;

    const dragPreview = document.createElement("div");
    dragPreview.style.width = "200px"; // Adjust the size as needed
    dragPreview.style.height = "auto"; // Let the height adjust based on content
    dragPreview.style.backgroundColor = "white";
    dragPreview.style.border = "1px solid #F0F0F0";
    dragPreview.style.paddingTop = "10px";
    dragPreview.style.display = "flex";
    dragPreview.style.flexDirection = "column";
    // dragPreview.style.alignItems = "center";
    // dragPreview.style.justifyContent = "flex-start"; // Align items to the start
    dragPreview.style.gap = "10px"; // Add space between the icon and file name
    dragPreview.style.opacity = "1";

    dragPreview.style.position = "absolute";
    dragPreview.style.top = "-1000px"; // Move it way offscreen
    dragPreview.style.left = "-1000px";
    dragPreview.style.zIndex = "999";
    dragPreview.style.borderRadius = "10px";

    // Add the selected items
    if (totalSelectedItems > 1) {
      keys.forEach((fileName) => {
        const fileContainer = document.createElement("div");
        fileContainer.style.display = "flex";
        fileContainer.style.alignItems = "center";
        fileContainer.style.gap = "5px"; // Space between icon and name
        fileContainer.style.transition = "all 0.3s ease"; // Smooth transition on hover
        fileContainer.style.borderBottomWidth = "1px";
        fileContainer.style.borderStyle = "solid";
        fileContainer.style.borderColor = "#F2F2F2";
        fileContainer.style.padding = "0 15px";
        fileContainer.style.paddingBottom = "10px";

        const fileIcon = document.createElement("div"); // Custom icon element
        fileIcon.style.width = "24px";
        fileIcon.style.height = "24px";
        fileIcon.style.backgroundColor = "#F0F0F0"; // Custom icon color
        fileIcon.style.borderRadius = "4px"; // Rounded corners
        fileIcon.style.display = "flex";
        fileIcon.style.alignItems = "center";
        fileIcon.style.justifyContent = "center";
        fileIcon.innerText = "F"; // Replace "F" with a custom symbol or icon

        const fileNameSpan = document.createElement("span");
        fileNameSpan.innerText = removeSlashAndExtension(fileName);
        fileNameSpan.style.fontSize = "14px";
        fileNameSpan.style.color = "#000";
        fileNameSpan.style.fontWeight = "600";
        fileNameSpan.style.width = "calc(100% - 24px)";
        fileNameSpan.style.whiteSpace = "nowrap";
        fileNameSpan.style.overflow = "hidden";
        fileNameSpan.style.textOverflow = "ellipsis";

        fileContainer.appendChild(fileIcon);
        fileContainer.appendChild(fileNameSpan);
        dragPreview.appendChild(fileContainer);

        // Apply the "dragging" style to each file container directly
        fileContainer.style.opacity = "1"; // Make sure it's fully visible
      });

      // Iterate over the keys2 array to add folder icons and names
      keys2.forEach((folderName) => {
        const folderContainer = document.createElement("div");
        folderContainer.style.display = "flex";
        folderContainer.style.alignItems = "center";
        folderContainer.style.gap = "5px"; // Space between icon and name
        folderContainer.style.transition = "all 0.3s ease"; // Smooth transition on hover

        const folderIcon = document.createElement("div"); // Folder icon element
        folderIcon.style.width = "24px";
        folderIcon.style.height = "24px";
        folderIcon.style.backgroundColor = "#F0F0F0"; // Folder icon color
        folderIcon.style.borderRadius = "4px"; // Rounded corners
        folderIcon.style.display = "flex";
        folderIcon.style.alignItems = "center";
        folderIcon.style.justifyContent = "center";
        folderIcon.innerText = "📁"; // Folder emoji as an icon

        const folderNameSpan = document.createElement("span");
        folderNameSpan.innerText = removeSlashAndExtension(folderName);
        folderNameSpan.style.fontSize = "14px";
        folderNameSpan.style.color = "#000";
        folderNameSpan.style.fontWeight = "600";
        folderNameSpan.style.width = "calc(100% - 24px)";
        draggedItemName.style.whiteSpace = "nowrap";
        draggedItemName.style.overflow = "hidden";
        draggedItemName.style.textOverflow = "ellipsis";

        folderContainer.appendChild(folderIcon);
        folderContainer.appendChild(folderNameSpan);
        dragPreview.appendChild(folderContainer);

        // Apply the "dragging" style to each folder container directly
        folderContainer.style.opacity = "1"; // Make sure it's fully visible
      });
    } else {

    }

    // Add the dragged item to the preview (whether it’s selected or not)
    const draggedItemContainer = document.createElement("div");
    draggedItemContainer.style.display = "flex";
    draggedItemContainer.style.alignItems = "center";
    draggedItemContainer.style.gap = "5px"; // Space between icon and name
    draggedItemContainer.style.transition = "all 0.3s ease"; // Smooth transition on hover
    draggedItemContainer.style.padding = "0 15px";
    draggedItemContainer.style.paddingBottom = "10px";

    const draggedItemIcon = document.createElement("div"); // Custom icon element for dragged item
    draggedItemIcon.style.width = "24px";
    draggedItemIcon.style.height = "24px";
    draggedItemIcon.style.backgroundColor = "#4caf50"; // Different color for dragged item
    draggedItemIcon.style.borderRadius = "4px"; // Rounded corners
    draggedItemIcon.style.display = "flex";
    draggedItemIcon.style.alignItems = "center";
    draggedItemIcon.style.justifyContent = "center";
    draggedItemIcon.innerText = "D"; // "D" for dragged item (replace with custom icon)

    const draggedItemName = document.createElement("span");
    draggedItemName.innerText = removeSlashAndExtension(file.fileName); // The dragged item's name
    draggedItemName.style.fontSize = "14px";
    draggedItemName.style.color = "#000";
    draggedItemName.style.width = "calc(100% - 24px)";
    draggedItemName.style.whiteSpace = "nowrap";
    draggedItemName.style.overflow = "hidden";
    draggedItemName.style.textOverflow = "ellipsis";

    draggedItemContainer.appendChild(draggedItemIcon);
    draggedItemContainer.appendChild(draggedItemName);
    dragPreview.appendChild(draggedItemContainer);

    // Attach the custom drag image
    document.body.appendChild(dragPreview);
    e.dataTransfer.setDragImage(dragPreview, 0, 0);

    // Apply a 'dragging' effect to all items in the preview
    const previewItems = dragPreview.querySelectorAll("div"); // All items in the preview
    previewItems.forEach(item => {
      item.style.opacity = "1"; // Ensure they are all visible during dragging
    });

    // Add the 'dragging' class to the target element
    e.target.classList.add("dragging");
  };


  // Function called when dragging over a folder
  const handleDragOver = (e) => {
    e.preventDefault(); // Necessary to allow a drop
    e.dataTransfer.dropEffect = "move";
  };

  const handleDragEnd = (e) => {
    e.target.classList.remove("dragging");
  };

  // Function called when a draggable item enters a droppable area
  const handleDragEnter = (e) => {
    e.preventDefault();
  };

  // Function called when the drop action occurs
  const handleDrop = (e, targetFolder) => {
    e.preventDefault();

    if (draggedItem && targetFolder.isFolder) {
      // Show move pop-up and then call move API
      console.log("Dragged item is", draggedItem, "Target folder is", targetFolder.isFolder)
      if (targetFolder.isFolder === true) {
        setDragPop(true)
        setDragFile(draggedItem)
        setTargetFolder(targetFolder.fileName)
      }

    }

    // Reset the dragged item
    setDraggedItem(null);
  };

  const moveDraggedFile = async (filename) => {
    if (filename.isFolder === true) {
      console.log("Shrey", [filename.fileName], [targetFolder])
      try {
        const uploadId = Date.now() + Math.random(); // Ensures a unique uploadId for each file
        addUpload(uploadId, "Moving " + filename.fileName);
        const res = await axios.post(
          `${apiUrl}move-folder`,
          {
            "sourceFolders": [filename.fileName],
            "destinationFolder": targetFolder,

          },
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            },
            onUploadProgress: (progressEvent) => {
              const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              updateUploadProgress(uploadId, progress);
            }
          }
        );
        removeUpload(uploadId)
        setDragPop(false)
        reloadAfterTast()
        console.log("Response is", res.data);
        showToast("success", "Folder Moved Successfully!")

      } catch (error) {
        showToast("error", "Failed to move folder!")
        console.error("Error moving file:", error);
      }
    }


    else {
      const uploadId = Date.now() + Math.random(); // Ensures a unique uploadId for each file
      const movingFile = filename.fileName;
      addUpload(uploadId, "Moving " + movingFile);
      console.log("Path to pass", path.replace(/\/$/, ''))
      try {

        const res = await axios.post(
          `${apiUrl}move-file`,
          {
            sourceFolder: path.replace(/\/$/, ''),
            destinationFolder: targetFolder,
            keys: [filename.fileName], // Handle each file (segment) separately
          },
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            onUploadProgress: (progressEvent) => {
              const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              updateUploadProgress(uploadId, progress);
            },
          }
        );
        setDragPop(false)
        removeUpload(uploadId)
        reloadAfterTast()
        console.log(`"${movingFile}" moved successfully:`, res.data);
        showToast("success", `"${movingFile}" Moved Successfully!`);
      } catch (error) {
        console.error(`Error moving file "${movingFile}":`, error);
        showToast("error", `Failed to move file "${movingFile}". Please try again.`);
      }
    }
  }

  //Move multiple files
  const moveMultipleDrag = async (arr, folname) => {
    try {
      const uploadId = Date.now() + Math.random()
      addUpload(uploadId, "Moving Files..");
      console.log("File to move is", arr)
      console.log("Path to pass", path.replace(/\/$/, ''))

      for (const key of arr) {
        console.log("key in array is ", key)
        const res = await axios.post(
          `${apiUrl}move-file`,
          {
            sourceFolder: path.replace(/\/$/, ''),
            destinationFolder: folname,
            keys: [key.substring(path.lastIndexOf('/') + 1)]
          },
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            onUploadProgress: (progressEvent) => {
              // Simulating progress for folder move
              const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              updateUploadProgress(uploadId, progress);
            }
          }
        );


        setKeys([])
        console.log(res)
        setDragPop(false)
        removeUpload(uploadId)
        reloadAfterTast()

        showToast("success", "Files Moved Successfully!");
      }
    }
    catch (error) {
      console.error(error)
    }
  }

  //Move multiple folders
  const moveMultipleDrag2 = async (arr, folname) => {
    try {
      if (arr && arr.length > 0) {
        const movingFolders = arr.join(', ');
        const uploadId = Date.now()
        addUpload(uploadId, "Moving " + movingFolders);

        const folderMoveRes = await axios.post(
          `${apiUrl}move-folder`,
          {
            "sourceFolders": arr,
            "destinationFolder": folname
          },
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            onUploadProgress: (progressEvent) => {
              const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              updateUploadProgress(uploadId, progress);
            }
          }
        );
        setKeys2([])
        setDragPop(false)
        removeUpload(uploadId)
        reloadAfterTast()
        console.log("Folders moved successfully:", folderMoveRes.data);
        showToast("success", "Folders Moved Successfully!");
      }
    }
    catch (error) {
      console.error(error)
    }
  }
  const [showDropdown, setShowDropdown] = useState(false);

  // Function to show the dropdown when the "Move" button is clicked
  const handleMoveClick = () => {
    setShowDropdown(!showDropdown);
  };

  const [folders, setFolders] = useState([]);

  useEffect(() => {
    if (showImage) {
      const fetchInitialFolders = async () => {
        try {
          const res = await axios.get(`${apiUrl}get-recent-folders`, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            }
          })
          console.log("Recent folders are", res.data.result)
          setFolders(res.data.result)
          console.log("folders are ", folders)
        }
        catch (error) {
          console.error("There is a error at", error)
        }
      }
      fetchInitialFolders()
    }
  }, [showImage]);

  const folderOptions = folders.map((folder) => ({
    value: getModifiedRecentFolderText(folder.folder),
    label: getModifiedRecentFolderText(folder.folder)
  }));
  const [selectedFolder, setSelectedFolder] = useState(null);

  const handleChange = (event) => {
    const selectedOption = folderOptions.find(
      (folder) => folder.value === event.target.value
    );
    setSelectedFolder(selectedOption.value);
    handleFolderSelect(selectedOption); // Pass the selected folder to the handler
  };

  const handleFolderSelect = async (selectedOption) => {
    console.log(modalFile)
    console.log(selectedOption)

    try {

      const res = await axios.post(
        `${apiUrl}move-file`,
        {
          sourceFolder: path.replace(/\/$/, ''),
          destinationFolder: selectedOption.value.replace(/</g, "/"),
          keys: [modalFile.substring(path.lastIndexOf('/') + 1)],
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },

        }
      );
      setSelectedFolder(null)
      console.log(res.data.message)

      async function executeFunctionsInOrder() {
        try {
          showToast('success', 'File Moved successfully');

          await reloadAfterTast();



          handleNext();
        } catch (error) {
          console.error('Error executing functions:', error);
        }
      }

      executeFunctionsInOrder();

    } catch (error) {
      console.error("There's a error");
      showToast("error", `Failed to move file.`);
    }
  };




  return (
    <>
      <ChakraProvider>

      </ChakraProvider>
      {
        infoShower &&
        <div title="" className="edit_popover common_popover">
          <div className="popover_content">
            <div className="edit_modal_content common_modal_style_content"></div>

            {/* File Information Pop-up */}
            <div className="file-popup" id="fileInfoPopup">
              <div className="file-popup-content">
                <span
                  className="file-popup-close"
                  id="closePopup"
                  onClick={() => { setInfoShower(false) }}
                >
                  &times;
                </span>
                <h2 id="fileName">{fileInfo.fileName.replace(path, "")}</h2>
                <p><strong>File Size:</strong> <span id="fileSize">{fileInfo.fileSize}</span></p>
                <p><strong>File Type:</strong> <span id="fileType">{fileInfo.fileType}</span></p>
                <p><strong>File URL:</strong> <a href="#" id="fileUrl">{fileInfo.fileUrl}</a></p>
                <p><strong>Upload Date:</strong> <span id="uploadDate">{fileInfo.uploadDateTime}</span></p>
              </div>
            </div>
          </div>
        </div>


      }
      {isVisibility &&
        <div title="" className='edit_popover common_popover'>
          <div className='popover_content'>
            <div className='edit_modal_content common_modal_style_content'>
              <ul className='radio_checkbox_list mt-4' style={{ justifyContent: 'center' }}>
                <button className="file-popup-close" onClick={() => { setIsVisibility(false) }}>&times;</button>
                <li>
                  <input
                    type="radio"
                    name="FileUpload2"
                    id="FilePublic2"
                    value="public"
                    onChange={handleRadioChange}
                    checked={pubpri2 === "public"}
                  />
                  <label htmlFor="FilePublic2">Public</label>
                </li>
                <li>
                  <input
                    type="radio"
                    name="FileUpload2"
                    id="FilePrivate2"
                    value="private"
                    onChange={handleRadioChange}
                    checked={pubpri2 === "private"}
                  />
                  <label htmlFor="FilePrivate2">Private</label>
                </li>
              </ul>
              <div className="action_button_container">
                <button className="change_visibility_button" onClick={() => { changeVisibility(visiKey) }}>
                  Change Visibility
                </button>
              </div>

            </div>
          </div>
        </div>
      }
      {renamePop &&
        <div title="" className='edit_popover common_popover'>
          <div className='popover_content'>
            <div className='edit_modal_content common_modal_style_content'>
              <div>
                <h5 className='card_title'>Rename File</h5>
                <div className="form_rename">
                  <input className="form__rename__input" type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleFileRename(newFileName, path + inputValue + extension)
                      }
                    }}
                  />

                  <div className='btn_tick_cross_row'>
                    <button onClick={() => { handleFileRename(newFileName, path + inputValue + extension) }} className='btn_tick'><i className='mdi mdi-check'></i></button>
                    <button onClick={() => { handleClosePopover() }} className='btn_cross'><i className='mdi mdi-window-close'></i></button>
                  </div>
                </div>
                <ul className='radio_checkbox_list mt-4' style={{ justifyContent: 'center' }}>

                </ul>

                {/* <div className='btn_group mt-4' style={{justifyContent:'center'}}>
                     <button className='btn_back btn_width_same btn_grey_ripple ripple_effect'>Cancel</button>
                     <button className='btn_gradient btn_width_same btn_red_ripple ripple_effect'>Submit</button>
                 </div> */}
              </div>

              <div className='thankyou_popover' style={{ display: 'none' }}>
                <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                  <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                  <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                </svg>
                <h1>Thank You!</h1>
                <p>For Rename File</p>
              </div>
            </div>
          </div>
        </div>
      }

      {deletePop &&
        <div title="" className='delete_popover common_popover'>
          <div className='popover_content'>
            <div className='delete_modal_content common_modal_style_content'>
              <h5>Are you sure you want to delete?</h5>
              <p>This action cannot be reversed</p>
              <div className='btn_group mt-4' style={{ justifyContent: 'center' }}>
                <button className='btn_back btn_width_same btn_grey_ripple ripple_effect' onClick={() => {
                  handleCloseDeletePopover();
                }} style={{ maxWidth: 110, height: 40 }}>No</button>
                <button onClick={() => handleFileDelete(activeDeleteRow)} className='btn_gradient btn_width_same btn_red_ripple ripple_effect' style={{ maxWidth: 110, height: 40 }}>Yes</button>
              </div>
            </div>
          </div>
        </div>
      }
      {dragPop &&
        <div title="" className='delete_popover common_popover'>
          <div className='popover_content'>
            <div className='delete_modal_content common_modal_style_content'>
              <h5>Are you sure you want to Move?</h5>
              <div className='btn_group mt-4' style={{ justifyContent: 'center' }}>
                <button className='btn_back btn_width_same btn_grey_ripple ripple_effect' onClick={() => {
                  setDragPop(false)
                }} style={{ maxWidth: 110, height: 40 }}>No</button>
                <button onClick={() => {

                  if (keys.length > 0) {
                    moveMultipleDrag(keys, targetFolder);
                  }
                  else {
                    moveDraggedFile(dragFile);
                  }
                  if (keys2.length > 0) {
                    moveMultipleDrag2(keys2, targetFolder);
                  }
                }
                } className='btn_gradient btn_width_same btn_red_ripple ripple_effect' style={{ maxWidth: 110, height: 40 }}>Yes</button>
              </div>
            </div>
          </div>
        </div>
      }
      {downloadPopup &&

        <div style={{ marginTop: 250, marginLeft: 570, zIndex: 999, height: 200, width: 400, borderRadius: 10, backgroundColor: 'white', position: 'fixed', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)' }}>
          <div className='delete_modal_content common_modal_style_content' >
            <h5 style={{ marginTop: 35 }}>Are you sure you want to download?</h5>
            <div className='btn_group mt-4' style={{ justifyContent: 'center' }}>
              <button className='btn_back btn_width_same btn_grey_ripple ripple_effect'
                onClick={() => {
                  setDownloadpopup(false);
                }} style={{ maxWidth: 110, height: 40 }}>No
              </button>
              {downloadLink && (
                <a className='btn_gradient btn_width_same btn_red_ripple ripple_effect'
                  style={{ maxWidth: 110, height: 40 }}
                  href={downloadLink.url} download={downloadLink.name}>
                  Yes
                </a>
              )}

            </div>
          </div>
        </div>

      }

      {sharePopup &&
        <div style={{ marginTop: 250, marginLeft: 570, zIndex: 999, height: 200, width: 400, borderRadius: 10, backgroundColor: 'white', position: 'fixed', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)' }}>
          <div class="bg-white rounded-lg  p-9 w-96 h-full">
            <h2 style={{ marginTop: 35, marginLeft: 130 }}>Copy Text</h2>
            <input style={{ marginLeft: 50, width: 300 }} onChange={(e) => setShareLink(e.target.value)} value={shareLink} id="inputField" type="text" class="w-full p-2 border rounded" placeholder="Enter text here" />
            <div className='btn_group mt-4' style={{ justifyContent: 'center', marginTop: 10 }}>
              <button id="copyButton" style={{ marginLeft: 10 }} class="btn_gradient btn_width_same btn_red_ripple ripple_effect" onClick={copyToClipboard}>Copy</button>
              <button onClick={() => { setSharepopup(false) }} style={{ marginLeft: 5 }} id="closePopup" class=" text-white rounded btn_back btn_width_same btn_grey_ripple ripple_effect">Close</button>
            </div>
          </div>
        </div>
      }

      <SideNav />

      <div className="container-fluid page-body-wrapper">
        {/* partial:partials/_navbar.html */}
        <nav className="navbar p-0 fixed-top d-flex flex-row">
          <div className="navbar-brand-wrapper d-flex d-lg-none align-items-center justify-content-center">
            <a className="navbar-brand brand-logo-mini" href="#"><img src={Logo} alt="logo" /></a>
          </div>
          <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
            <ToggleNav />
            <div className="navbar-nav page_title">
              <h1>STOLITY</h1>

              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><Link to="/Files">Home</Link></li>
                  <li class="breadcrumb-item">
                    {parts.map((part, index) => (
                      <span key={index}>
                        <a href="#" onClick={(event) => handleBreadClick(event, part, index, parts.length)}>

                          {part}
                        </a>
                        {index < parts.length - 1 && <span> &gt; </span>}
                      </span>
                    ))}
                  </li>
                </ol>
              </nav>

              {/* <div className="breadcrumb">
            {<Link to="/Files">← Back</Link>} &gt;&nbsp;
            {parts.map((part, index) => (
            <span key={index}>
            <a href="#" onClick={(event) => handleBreadClick(event, part, index, parts.length)}>
             
             {part}
            </a>
            {index < parts.length - 1 && <span> &gt; </span>}
            </span>
            ))}

            </div> */}





            </div>

          </div>
        </nav>
        {/* partial */}
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="table_box">
              <div className='filerbar_row'>
                <div className='show_entries_row'>
                  <div className='fiter_search'>
                    <input value={query}
                      onChange={(e) => setQuery(e.target.value)}
                      type='text' className='form-control' placeholder='Search...' />

                  </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: 15 }}>
                  <div className="filterbar">
                    <div className="dropdown dropdown-processed" style={{ width: '-webkit-fill-available' }}>
                      <Dropdown onSelect={handleFTypeSelect} title={
                        <span>
                          <i className="icon-filter" /> Filter
                        </span>
                      } className="filter_dropdown">

                        <Dropdown.Item eventKey="File Type">File Type</Dropdown.Item>
                      </Dropdown>

                      <BootstrapModal show={showFTPopup} onHide={closePopup} className='modalFileType'>
                        <BootstrapModal.Header closeButton>
                          <BootstrapModal.Title>File Type Filter</BootstrapModal.Title>
                        </BootstrapModal.Header>
                        <BootstrapModal.Body>
                          <p>Select the file types you want to filter by:</p>

                          <ul className="radio_checkbox_list">
                            {fileTypes.map((fileType) => (
                              <li key={fileType}>
                                <input
                                  type="checkbox"
                                  id={fileType}
                                  value={fileType}
                                  checked={selectedFileTypes.includes(fileType)}

                                  onChange={() => handleFTCheckboxChange(fileType)}
                                />
                                <label htmlFor={fileType} style={{}}>
                                  {fileType.toUpperCase()}
                                </label>
                              </li>
                            ))}
                          </ul>

                        </BootstrapModal.Body>
                        <BootstrapModal.Footer>
                          {/* <button onClick={closePopup}>Close</button>
                        <button onClick={applyFilter} className="btn btn-primary">Filter</button> */}
                          <div class="btn_group" style={{ width: '100%', justifyContent: 'center' }}>
                            <button onClick={closePopup} class="btn_back btn_width_same btn_grey_ripple ripple_effect">Cancel</button>
                            <button onClick={applyFilter} class="btn_gradient btn_width_same btn_red_ripple ripple_effect">Filter</button>
                          </div>

                        </BootstrapModal.Footer>
                      </BootstrapModal>



                    </div>
                    <div className="dropdown dropdown-processed" style={{ borderLeft: "1px solid #d7d6ef", width: '-webkit-fill-available' }}>
                      {/* <a className="dropdown-link" href="#"><i className='icon-sorting'></i> <span id="sortingtxt">Sorting</span></a> */}
                      <Dropdown onSelect={handleFilterSelect} title={
                        <span>
                          <i className="icon-sorting" /> {selectedFilter}
                        </span>
                      } className="filter_dropdown">
                        <Dropdown.Item eventKey="name-filter1">By Name(A-Z)</Dropdown.Item>
                        <Dropdown.Item eventKey="name-filter2">By Name(Z-A)</Dropdown.Item>
                        <Dropdown.Item eventKey="size-filter1">By Size(Ascending)</Dropdown.Item>
                        <Dropdown.Item eventKey="size-filter2">By Size(Descending)</Dropdown.Item>
                        <Dropdown.Item eventKey="date-filter1">By Date(Oldest)</Dropdown.Item>
                        <Dropdown.Item eventKey="date-filter2">By Date(Newest)</Dropdown.Item>
                      </Dropdown>
                    </div>
                  </div>
                  <button onClick={handleOpenCreateFolder} className='btn__upload__file_modal'><img src={IconUpload} />Create Folder</button>
                  <button onClick={handleOpenFileUploadModal} className='btn__upload__file_modal'><img src={IconUpload} />Upload Files</button>
                </div>
              </div>

              {(keys.length > 0 || keys2.length > 0) && (
                <div className="selected_table_row">
                  <div className='selected_table_text'>
                    <button onClick={() => { setKeys([]); setKeys2([]) }} className='selected_close_table'><i className='icon-cross'></i></button>
                    <span>{keys.length + keys2.length} Selected</span>
                    <button
                      onClick={handleSelectAllToggle}
                      class="button-18"
                    >
                      {isSelectAll ? 'Deselect All' : 'Select All'}
                    </button>
                  </div>

                  <ul className='selected_table_icons'>
                    <li><button onClick={() => {
                      if (keys2.length > 0) {
                        showToast("error", "Copy folder is not available!");
                      } else {
                        setIsCWhisperClicked(true);
                      }
                    }} class="icon-copy"></button></li>
                    <li><button onClick={() => { setIsWhisperClicked(true) }} class="icon-move"></button></li>
                    <li><button onClick={() => { handleMulDelete() }} class="icon-delete2"></button></li>
                  </ul>
                </div>)}

              {isLoading ? (
                <Placeholder.Grid rows={10} columns={5} active style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 12 }} />
              ) :
                (
                  <div className='table-responsive'>
                    <table id="filestable" className="table table-striped">
                      <thead>

                        <tr>
                          <th width="35" align="center">&nbsp;</th>
                          <td width={35} align="center"><b>No.</b></td>
                          <th width={35}>Ext.</th>
                          <th width="50%" className="dropdown dropdown-processed">

                            <span style={{ fontWeight: 600, color: '#181818', }}>
                              File Name
                            </span>

                          </th>
                          <th width={90}>&nbsp;&nbsp;</th>
                          <th width={75}>File Size</th>
                          <th width={90}>Modified On</th>
                          <th width={30}>&nbsp;</th>
                        </tr>

                      </thead>
                      {paginatedData.map((file, index) => {
                        return (
                          <tbody>

                            <tr className={`hover_cell ${activeRow === 1 ? 'active-row' : ''}`}
                              draggable={true}
                              onDragStart={(e) => handleDragStart(e, file)}
                              onDragOver={file.isFolder ? handleDragOver : null}
                              onDragEnter={file.isFolder ? handleDragEnter : null}
                              onDragEnd={handleDragEnd}
                              onDrop={file.isFolder ? (e) => handleDrop(e, file) : null}
                            >
                              <td>
                                <input id="check-Atharva" type="checkbox" onChange={() => handleCheckboxChange(file)}
                                  checked={file.isFolder ? keys2.includes(file.fileName) : keys.includes(file.fileName)}
                                >
                                </input>
                              </td>





                              <td align="center">{index + 1}</td>
                              <td>
                                <span className="filename_link" style={{ cursor: 'pointer' }}>
                                  <img src={file.icon} height={32} alt="File Icon" />
                                </span>
                              </td>
                              <td>
                                <span className="filename_link" style={{ cursor: 'pointer' }}

                                  onClick={
                                    () => {
                                      setErrorMessage2("")
                                      if (file.fileType === 'mkv' || file.fileType === 'mp4' || file.fileType === 'mov' || file.fileType === 'mpeg' || file.fileType === 'webm' || file.fileType === 'MOV') {

                                        setModalFile(file.fileName)
                                        handleImageShow()
                                        const fname = file.fileName


                                        const index = paginatedData.findIndex(file => file.fileName === fname);
                                        if (index !== -1) {
                                          setCurrentImageIndex(index);
                                          console.log("index is ", index)
                                        } else {

                                          console.warn(`File with name "${fname}" not found in imageArray`);
                                        }
                                        setVideoSrc(file.fileName)


                                      }
                                      else if (file.fileType === 'jpeg' || file.fileType === 'jpg' || file.fileType === 'png' || file.fileType === 'gif' || file.fileType === 'hevc' || file.fileType === 'heif' || file.fileType === 'JPEG' || file.fileType === 'JPG' || file.fileType === 'PNG' || file.fileType === 'GIF' || file.fileType === 'HEVC' || file.fileType === 'HEIF' || file.fileType === 'svg' || file.fileType === 'SVG' || file.fileType === 'webp' || file.fileType === 'WEBP') {

                                        setModalFile(file.fileName)
                                        handleImageShow()
                                        const fname = file.fileName
                                        console.log("Starting function call with fname:", fname);

                                        const index = paginatedData.findIndex(file => file.fileName === fname);
                                        if (index !== -1) {
                                          setCurrentImageIndex(index);
                                          console.log("index is ", index)
                                        } else {
                                          console.warn(`File with name "${fname}" not found in imageArray`);
                                        }


                                        getImageInfo(file.fileName)


                                      }
                                      else if (file.fileType === 'mp3' || file.fileType === 'MP3' || file.fileType === 'wav' || file.fileType === 'WAV' || file.fileType === 'ogg' || file.fileType === 'OGG' || file.fileType === 'aac' || file.fileType === 'AAC') {

                                        setModalFile(file.fileName)
                                        handleImageShow()
                                        const fname = file.fileName
                                        console.log("Starting function call with fname:", fname);

                                        const index = paginatedData.findIndex(file => file.fileName === fname);
                                        if (index !== -1) {
                                          setCurrentImageIndex(index);
                                          console.log("index is ", index)
                                        } else {
                                          console.warn(`File with name "${fname}" not found in imageArray`);
                                        }
                                        getAudioInfo(file.fileName)
                                      }


                                      else if (file.fileType === 'pdf' || file.fileType === 'PDF' || file.fileType === 'txt' || file.fileType === 'TXT') {

                                        setModalFile(file.fileName)
                                        handleImageShow()
                                        const fname = file.fileName
                                        console.log("Starting function call with fname:", fname);

                                        const index = paginatedData.findIndex(file => file.fileName === fname);
                                        if (index !== -1) {
                                          setCurrentImageIndex(index);
                                          console.log("index is ", index)
                                        } else {
                                          console.warn(`File with name "${fname}" not found in imageArray`);
                                        }
                                        getPdfInfo(file.fileName)
                                      }


                                      else {
                                        if (file.isFolder === true) {
                                          chkFileorFolder(file, file.fileSize)
                                        }
                                        else {
                                          handleImageShow()
                                          setErrorMessage2("File format not supported!")

                                          const fname = file.fileName
                                          console.log("Starting function call with fname:", fname);

                                          const index = paginatedData.findIndex(file => file.fileName === fname);
                                          if (index !== -1) {
                                            setCurrentImageIndex(index);
                                            console.log("index is ", index)
                                          } else {
                                            console.warn(`File with name "${fname}" not found in imageArray`);
                                          }
                                        }

                                      }
                                    }
                                  }>
                                  {customTruncateFileName(removeSlashAndExtension(file.fileName), 55)}
                                </span>
                              </td>
                              <td>
                                {(keys.length === 0 && keys2.length === 0) && (
                                  <ul className="table_hover_links">
                                    <li>

                                      {file.isFolder === false && (
                                        <Whisper
                                          speaker={<Tooltip className="tooltip-custom">Click to Change Visibility</Tooltip>}
                                          trigger="hover"
                                          placement="top"
                                        >
                                          <a href='#' style={{ color: '#4256b3' }}
                                            onClick={() => {
                                              setIsVisibility(true)
                                              setVisiKey(file.fileName)
                                              setPubPri2(file.ACL)
                                            }}>
                                            <i className="icon-view" style={{ fontSize: 21 }} />
                                          </a>
                                        </Whisper>
                                      )}

                                    </li>
                                    <li>
                                      <button onClick={() => handleOpenPopover(file)}>
                                        <i class="icon-edit-fill"></i>
                                      </button>
                                    </li>

                                    {file.isFolder === false && (
                                      <li>
                                        <Whisper speaker={<Tooltip className="tooltip-custom">Click to Copy</Tooltip>} trigger="hover"
                                          placement="top"
                                        ><a onClick={() => {
                                          if (file.isFolder) {
                                            showToast('error', 'Copy Folder is not available!')
                                          } else {
                                            handleCClick(file.fileName);
                                          }
                                        }}
                                          href='#'><i className="icon-copy-fill" /></a>
                                        </Whisper>
                                      </li>
                                    )}
                                    {file.isFolder === false && (
                                      <li>
                                        <Whisper speaker={<Tooltip className="tooltip-custom">Click to Download</Tooltip>} trigger="hover"
                                          placement="top"
                                        ><a onClick={() => {
                                          if (file.isFolder) {
                                            showToast("error", "Download folder is not available!");
                                          } else {
                                            downloadFile(file);
                                          }
                                        }} href='#'><i className="icon-cloud-download" /></a></Whisper>
                                      </li>)}
                                    <li>
                                      <Whisper speaker={<Tooltip className="tooltip-custom">Click to Move</Tooltip>} trigger="hover"
                                        placement="top"
                                      ><a
                                        onClick={() => {
                                          if (file.isFolder) {
                                            handleMFClick(file.fileName);
                                          } else {
                                            handleMClick(file.fileName);
                                          }
                                        }}
                                        href='#' style={{ color: '#4256b3' }}><i className="icon-move" style={{ fontSize: 21 }} /></a></Whisper>
                                    </li>
                                    {file.isFolder === false && (
                                      <li>
                                        <Whisper speaker={<Tooltip className="tooltip-custom">Click to Share</Tooltip>} trigger="hover"
                                          placement="top"
                                        ><a onClick={() => {
                                          if (file.isFolder) {
                                            showToast("error", "Share folder is not available!");
                                          } else {
                                            shareFile(file);
                                          }
                                        }} href='#' style={{ color: '#4BBF8E' }}><i className="icon-share" style={{ fontSize: 21 }} /></a></Whisper>
                                      </li>)}
                                    {file.isFolder === false && (
                                      <li>
                                        <Whisper speaker={<Tooltip className="tooltip-custom">Information</Tooltip>} trigger="hover"
                                          placement='bottom'

                                        ><a onClick={() => {
                                          if (file.isFolder) {
                                            showToast("error", "Folder information is not available!")
                                          } else {
                                            setInfoShower(true)
                                            getFileInfo(file.fileName)
                                          }
                                        }} href='#' style={{ color: '#4256b3' }}><i className="mdi mdi-information-outline" style={{ fontSize: 25 }} /></a></Whisper>
                                      </li>)}
                                  </ul>
                                )}
                              </td>
                              <td class="fileSizeTL" data-sort={1673004}>{file.fileSize}</td>
                              <td class="fileSizeTD" data-sort="2023-12-16 07:32:38">
                                <p>{file.uploadDateTime.substring(0, file.uploadDateTime.indexOf(','))}</p>
                                <span>{file.uploadDateTime.substring(file.uploadDateTime.indexOf(',') + 1).trim()}</span>
                              </td>

                              <td className="actions">
                                {(keys.length === 0 && keys2.length === 0) && (

                                  <button onClick={() => handleOpenDeletePopover(file)} class="icon_delete_btn ripple_effect"><i class="icon-delete"></i></button>
                                )}
                              </td>
                            </tr>


                          </tbody>
                        )
                      })}

                    </table>
                  </div>
                )
              }

              {isWhisperClicked && <MoveFilePopup moveKey={movedFile} source={path} onClose={handleMClose} files={keys} folders={keys2} />
              }

              {moveFol && <MoveFolderPopup moveKey={movedFol} source={""} onClose={handleMFClose} />
              }

              {isCWhisperClicked && <CopyFilePopup moveKey={copiedFile} source={path} onClose={handleCClose} files={keys} />}
              <div className='container-fluid'>
                <div className="row align-items-center">
                  <div className="col-sm-12 col-md-5 mt-4">
                    <div className="dataTables_info">Showing{" "}
                      {currentPage > 1 ? (currentPage * 10) - 9 : currentPage} to{" "}

                      {
                        currentPage > 1
                          ? currentPage === Math.ceil(responseData.length / endIndex)
                            ? responseData.length
                            : currentPage * 10
                          : paginatedData.length
                      }{" "}
                      of {responseData.length} entries</div>
                  </div>
                  <div className="col-sm-12 col-md-7 mt-4">
                    <div className="dataTables_paginate paging_simple_numbers">
                      <div class="dataTables_length">
                        <label className='label_show_drop'>Show</label>
                        <SelectPicker
                          data={data}
                          searchable={false}
                          style={{ width: 90, }}
                          placeholder=""
                          onSelect={(value) => pageFilter(value)}
                        />
                        <label>entries</label>
                      </div>

                      <ul className="pagination">
                        <li className="page-item">
                          <button
                            onClick={() => setCurrentPage(currentPage - 1)}
                            disabled={currentPage === 1}
                            className="page-link"
                            style={{ height: '39px' }}
                          >
                            Previous
                          </button>
                        </li>

                        <li className="page-item">
                          <button
                            onClick={() => setCurrentPage(currentPage)}
                            className={`page-link ${currentPage === currentPage ? 'active' : ''}`}
                            style={{ height: '39px', width: '39px' }}
                          >
                            {currentPage}
                          </button>
                        </li>

                        <li className="page-item">
                          <button
                            onClick={() => setCurrentPage(currentPage + 1)}
                            className={`page-link ${currentPage + 1 === currentPage ? 'active' : ''}`}
                            disabled={currentPage >= totalPages}
                            style={{ height: '39px', width: '39px' }}
                          >
                            {currentPage >= totalPages ? "-" : currentPage + 1}
                          </button>
                        </li>

                        <li className="page-item">
                          <button
                            onClick={() => setCurrentPage(currentPage + 2)}
                            className={`page-link ${currentPage + 2 === currentPage ? 'active' : ''}`}
                            disabled={currentPage >= totalPages - 1}
                            style={{ height: '39px', width: '39px' }}
                          >
                            {currentPage >= totalPages - 1 ? "-" : currentPage + 2}
                          </button>
                        </li>

                        <li className="page-item">
                          <button
                            onClick={() => setCurrentPage(currentPage + 1)}
                            disabled={currentPage >= totalPages}
                            className="page-link"
                            style={{ height: '39px' }}
                          >
                            Next
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card-header use_storage">
                <span>This folder is using {value}</span>
              </div>
            </div>

          </div>


          <Footer />
        </div>
        {/* main-panel ends */}
      </div>

      {/*All files Shower */}
      <Modal
        open={showImage}
        onClose={handleImageClose}
        className={`pdf_modal_style file_upload_modal_style gallery_modal_wrapper ${isFullscreen ? 'fullscreen-modal' : ''}`}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          // backgroundColor: isFullscreen ? 'rgba(0, 0, 0, 0.8)' : 'rgba(0, 0, 0, 0.5)',
          // padding: isFullscreen ? '0' : '10px',
        }}
      >
        <Button
          onClick={handleImageClose}
          variant="secondary"
          className="file-popup-close"
          id="closePopup"
          style={{ position: 'absolute', top: '8px', right: '8px', fontSize: '32px', color: '#525252', width: '50px', background: 'none', zIndex: '999', opacity: '100', left: 'inherit' }}
        >
          &times; {/* Close Icon */}
        </Button>

        <Modal.Body style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
          {isProgressVisible && (
            <span className="loader" ></span> // Added loader at the top
          )}

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', position: 'relative' }}>
            <Button className="btn_gallery_controls" onClick={handlePrev} style={{ position: 'absolute', left: 12, width: 24, height: 24, borderRadius: 40, background: '#FFF', boxShadow: '0 0 2px #DDD', zIndex: 9 }}><i className='fa fa-chevron-left'></i></Button>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                // backgroundColor: isFullscreen ? 'rgba(0, 0, 0, 0.8)' : 'rgba(0, 0, 0, 0.5)',
                // padding: isFullscreen ? '0' : '20px',
                width: isFullscreen ? '100%' : '100%',
                height: isFullscreen ? '100%' : '90%',
                maxWidth: '100%',
                maxHeight: '90%',
                overflow: 'hidden',
                borderRadius: 18,
                marginTop: 15,
              }}
            >
              {videoSrc ? (
                <ReactPlayer
                  url={`${apiUrl}getFileDefault?token=${token}&filePath=${videoSrc}`}
                  controls
                  playing={true}
                  width="100%"
                  height="100%"

                />
              ) : pdfSrc ? (
                <iframe src={pdfSrc} border="0" width="100%" style={{ height: '100vh', border: 0 }} />
              ) : imageSrc ? (
                <img src={imageSrc} alt="Image format not supported" style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
              ) : audioSrc ?
                (
                  <div className='audio_player_modal' style={{ overflow: 'hidden', borderRadius: 18 }}>
                    <audio controls style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                      position: 'relative',
                    }}>
                      <source src={audioSrc} type="audio/ogg" />
                      <source src={audioSrc} type="audio/mpeg" />
                      <source src={audioSrc} type="audio/wav" />
                      <source src={audioSrc} type="audio/mp3" />
                      Your browser does not support the audio element.
                    </audio>


                  </div>
                )
                :
                (
                  errorMessage2 ? <p>{errorMessage2}</p> :
                    <p></p>
                )
              }
            </div>


            <Button className="btn_gallery_controls" onClick={handleNext} style={{ position: 'absolute', right: 12, boxShadow: '0 0 2px #DDD', width: 24, height: 24, borderRadius: 40, background: '#FFF', zIndex: 9 }}><i className='fa fa-chevron-right'></i></Button>
          </div>



          <div style={{ marginTop: '15px', display: 'flex', gap: '10px' }}>
            <div className='zoom_btn_group'>
              <Button onClick={zoomIn}>
                <img src={IconZoomIn} alt="Zoom In" style={{ width: '24px', height: '24px' }} />
              </Button>
              <span>Zoom</span>
              <Button onClick={zoomOut}>
                <img src={IconZoomOut} alt="Zoom Out" style={{ width: '24px', height: '24px' }} />
              </Button>
            </div>
            <Button onClick={toggleFullscreen} className="btn_fullscreen_gallery">
              <img src={IconFullScreen} alt="Zoom Out" style={{ width: '24px', height: '24px' }} />
              <span>Fullscreen</span>
            </Button>


            <Button className="btn_delete_gallery" onClick={() => deleteFromModal(modalFile)}>
              <img src={IconDelete} alt="Remove" style={{ width: '24px', height: '24px' }} />
              <span>Remove</span>
            </Button>
          </div>

          <div style={{ marginTop: '30px', display: 'flex', gap: '20px' }}>
            <div style={{ marginTop: "20px", display: 'flex', gap: 12 }}>
              <label style={{ fontWeight: "600", fontFamily: 'Inter', color: '#475467', marginBottom: "10px", display: "block", whiteSpace: 'nowrap' }}>
                Move To:
              </label>
              <ul class="radio_checkbox_list gallery_modal_radio_list" style={{ marginTop: 0, fontSize: 12, fontWeight: 500, fontFamily: 'Inter' }}>
                {folderOptions
                  .filter((folder) => folder.value.trim() !== "" && folder.label.trim() !== "") // Exclude empty or whitespace-only options
                  .map((folder) => (
                    <li key={folder.value} style={{}}>
                      <input
                        type="radio"
                        id={folder.value}
                        name="folder"
                        value={folder.value}
                        checked={selectedFolder === folder.value}
                        onChange={handleChange}
                      />
                      <label htmlFor={folder.value} style={{}}>
                        {folder.label}
                      </label>
                    </li>
                  ))}

              </ul>
            </div>
          </div>
        </Modal.Body>
      </Modal>


      <Modal open={openFileUploadModal} onClose={handleCloseFileUploadModal} className='pdf_modal_style file_upload_modal_style' style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
        <Modal.Body style={{ width: '100%' }}>
          <div className=''>
            <Tabs>
              <TabList>
                <Tab>
                  <i className='mdi mdi-file-document-box-multiple-outline'></i> Files
                </Tab>
                <Tab>
                  <i className='mdi mdi-folder-multiple-outline'></i> Upload Folder
                </Tab>
                <Tab>
                  <i className='mdi mdi-folder-multiple-outline'></i> Folder
                </Tab>
              </TabList>

              <TabPanel>
                <h5 className='card-title'>Upload File {"-" + path.replace(/\//g, '>')}</h5>

                <Dropzone onDrop={onDrop}>
                  {({ getRootProps, getInputProps }) => (
                    <section className="">
                      <div {...getRootProps({ className: 'fileupload' })}>
                        <input
                          {...getInputProps()} />
                        <p>Drag your documents, photos, or videos here to start uploading.<br />
                          <span className='btn_choosefile'>Choose Files</span></p>
                      </div>
                      <ul className='upload_thumbnails_list'>{fileList2}</ul>
                    </section>
                  )}
                </Dropzone>

                <ul className='radio_checkbox_list'>
                  <li>
                    <input
                      type="radio"
                      name="FileUpload"
                      id="FilePublic"
                      value="public-read"
                      onChange={handlePubChange}
                    />
                    <label htmlFor="FilePublic">Public</label>
                  </li>
                  <li>
                    <input
                      type="radio"
                      name="FileUpload"
                      id="FilePrivate"
                      value="private"
                      onChange={handlePubChange}
                      defaultChecked
                    />
                    <label htmlFor="FilePrivate">Private</label>
                  </li>
                </ul>

                <div className='btn_group mt-4'>
                  <button onClick={handleCloseFileUploadModal} className='btn_back btn_width_same btn_grey_ripple ripple_effect'>Close</button>
                  <button onClick={handleFileUpload} className='btn_gradient btn_width_same btn_red_ripple ripple_effect'>Submit</button>
                </div>



              </TabPanel>

              <TabPanel>
                <h5 className='card-title'>Upload Folder {"-" + path.replace(/\//g, '>')}</h5>

                <div {...getRootProps({ className: 'fileupload' })}>
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <p>Drop the folder here ...</p>
                  ) : (
                    <p>Drag your folder here to start uploading, or</p>
                  )}
                  <button onClick={handleSelectFolder} className='btn_choosefile'>
                    Choose Folder
                  </button>
                </div>
                {files.length > 0 && (
                  <ul className='upload_thumbnails_list font-extrabold'>
                    {nameOfFolder}
                  </ul>
                )}

                <ul className='radio_checkbox_list'>
                  <li><input type="radio" name="FileUpload3"
                    onChange={handleRadioChange2} value='public-read'
                    id="FilePublic3" /><label htmlFor="FilePublic3">Public</label></li>
                  <li><input type="radio" name="FileUpload3"
                    onChange={handleRadioChange2} value='private'
                    id="FilePrivate3" defaultChecked /><label htmlFor="FilePrivate3">Private</label></li>
                </ul>

                <div className='btn_group mt-4'>
                  <button onClick={handleCloseFileUploadModal} className='btn_back btn_width_same btn_grey_ripple ripple_effect'>Close</button>
                  <button onClick={uploadFolder} className='btn_gradient btn_width_same btn_red_ripple ripple_effect'>Submit</button>
                </div>
              </TabPanel>

              <TabPanel>
                <h5 className='card-title'>Create Folder </h5>
                <form class="form page__form max_width_div" action="javascript:void(0);" method="POST" onSubmit={createJustFolder}>
                  <div className="form__linput">
                    <input class="form__input" type="text" name="fname" id="folname" pattern="\w{1,}" required />
                    <label class="form__label" for="fname">Enter Your Folder Name</label>
                    {folderFieldError && <p className="error-message">{folderFieldError}</p>}
                  </div>
                </form>


                <div className='btn_group mt-4'>
                  <button onClick={handleCloseFileUploadModal} className='btn_back btn_width_same btn_grey_ripple ripple_effect'>Close</button>
                  <button onClick={createJustFolder} className='btn_gradient btn_width_same btn_red_ripple ripple_effect'>Submit</button>

                </div>

              </TabPanel>

            </Tabs>
          </div>
        </Modal.Body>
      </Modal>





      <Modal open={createFolderButton} onClose={handleCloseCreateFolder} className='pdf_modal_style file_upload_modal_style' style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
        <Modal.Body style={{ width: '100%' }}>
          <div className=''>
            <Tabs>
              <TabList>
                <Tab>
                  <i className='mdi mdi-folder-multiple-outline'></i> Folder
                </Tab>
              </TabList>
              <TabPanel>
                <h5 className='card-title'>Create Folder </h5>
                <form class="form page__form max_width_div" action="javascript:void(0);" method="POST" onSubmit={createJustFolder}>
                  <div className="form__linput">
                    <input class="form__input" type="text" name="fname" id="folname" pattern="\w{1,}" required />
                    <label class="form__label" for="fname">Enter Your Folder Name</label>
                    {folderFieldError && <p className="error-message">{folderFieldError}</p>}
                  </div>
                </form>


                <div className='btn_group mt-4'>
                  <button onClick={handleCloseCreateFolder} className='btn_back btn_width_same btn_grey_ripple ripple_effect'>Close</button>
                  <button onClick={createJustFolder} className='btn_gradient btn_width_same btn_red_ripple ripple_effect'>Submit</button>

                </div>

              </TabPanel>

            </Tabs>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default NestedPage