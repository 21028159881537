import { createSlice } from "@reduxjs/toolkit";




const initialState = {
    userdata: [],
    folderList: [],
    folderCounter:0,
    counter: 0,
    folderName:""
};

const fileSlicer = createSlice({
    
    name: "getdata",
    initialState,
    reducers: {
        addToken: (state, action) => {
            const exists = state.userdata.some(user => user.id === action.payload.id);
            if (!exists) {
                state.userdata.push(action.payload);
            }
        },
        addFolder: (state, action) => {    
               if (Array.isArray(action.payload)) {                
                state.folderList = state.folderList.concat(action.payload);  
               } 
                else {        
                console.error('Payload is not in the expected format');  
        }},
        addNewFolder: (state, action) => {
            // Set folderCounter to 1
            state.folderCounter = 1;
        
            // Check if the payload is an array
            if (Array.isArray(action.payload)) {
                // Replace folderList with only the 0th index from the payload
                state.folderList = [action.payload[0]];
            } else {
                console.error('Payload is not in the expected format');
            }
        }
        
        ,
        removeLastFolder : (state) => {
            // Check if folderList is not empty
            if (state.folderList.length > 0) {
                // Remove the last record
                state.folderList.pop();
            } else {
                console.error('No records to remove');
            }
        },
        incrementCounter: (state) => {
            state.counter += 1;
        },
        incrementFCounter: (state) => {
            state.folderCounter += 1;
        },
        removeLastToken: (state) => {
            if (state.userdata.length > 0) {
                state.userdata.pop();
            }
        },
        decrementCounter:(state)=>{
            state.counter -= 1;
        },
        decrementFCounter:(state)=>{
            state.folderCounter -= 1;
        },
        resetUserData: (state) => {
            state.userdata = [];
        },
        resetCounter: (state) => {
            state.counter = 0;
        },
        resetFolderList: (state) => {
            // Set folderCounter to 1
            state.folderCounter = 1;
        
            // Keep only the 0th index and remove everything else
            state.folderList = state.folderList.length > 0 ? [state.folderList[0]] : [];
        }
        ,
        setFolderPath: (state, action) => {
            state.folderName = action.payload.folderPath;
        },
        replacelasttoken: (state, action) => {   
             if (state.userdata.length > 0) {        
                state.userdata.pop();   
                state.userdata.push(action.payload);
             }    
             
        },
        breadCrum: (state, action) => {
            
        const validNumber = Number(action.payload.number);
        console.log("validNumber", validNumber)
  
        if (state.userdata.length > 0) {
            console.log("another",state.userdata.length,validNumber)
        const ans = (state.userdata.length - 1) - validNumber;

        console.log("beyblade", validNumber, ans);

        if (ans >= 0 && ans <= state.userdata.length) {
        
          for (var i=1;i<ans;i++){
            state.userdata.pop();
            state.counter--
          }

        }
   

        } else {
        console.log("Invalid userdata or number");
        }
}
          
          
          

        
        
    }
});

export const { addToken, addFolder,addNewFolder, incrementCounter,removeLastToken,decrementCounter,resetUserData, resetCounter, setFolderPath,incrementFCounter,removeLastFolder,decrementFCounter,replacelasttoken,breadCrum,resetFolderList} = fileSlicer.actions;
export default fileSlicer.reducer;
